import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Config from '../../../Config';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import { FaPlus, FaFileCsv } from 'react-icons/fa';
/* import CsvDownloader from 'react-csv-downloader';
import UserPdf from '../createPdf/UserPdf'; */

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';

import { FaCircle } from "react-icons/fa";
import ModalAnadirIngresoInsumo from '../../forms/IngresoInsumos/ModalAnadirIngresoInsumo';
import ModalEditarIngresoInsumo from '../../forms/IngresoInsumos/ModalEditarIngresoInsumo';

export default function IngresoInsumosTable() {

    const { getRol } = AuthUser();

    const [ingresoInsumos, setIngresoInsumos] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [rowCount, setRowCount] = useState(0);

    //Modal
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [editId, setEditId] = useState('');

    //Para actualizar luego de agregar o editar
    const updateInsumoList = async () => {
        await getIngresoInsumosAll();
    };

    useEffect(() => {
        getIngresoInsumosAll();
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        columnFilters,
        globalFilter,
        sorting
    ]);

    const getIngresoInsumosAll = async () => {
        setTableLoading(true);

        let getIngresoInsumosList;

        if (getRol() === "Administrador") {
            getIngresoInsumosList = Config.getIngresoInsumosListAdmin(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        } else if (getRol() === "Operario") {
            getIngresoInsumosList = Config.getIngresoInsumosListOperario(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        } else if (getRol() === "Almacenero") {
            getIngresoInsumosList = Config.getIngresoInsumosListAlmacenero(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        }

        try {
            const response = await getIngresoInsumosList;
            console.log(response);
            if (response.data.exito == '0') {
                notify(response.data.message, 'error')
            } else {
                //console.log(response);
                setIngresoInsumos(response.data.data);
                setRowCount(response.data.total);
                //setClientes(response.data);
                /* console.log(response); */
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setTableLoading(false);
        }
    }

    const deleteOneIngresoInsumo = async (id) => {
        setTableLoading(true);
        let deleteOneInsumo;

        if (getRol() === "Administrador") {
            deleteOneInsumo = Config.deleteOneIngresoInsumoAdmin(id);
        } else if (getRol() === "Operario") {
            deleteOneInsumo = Config.deleteOneIngresoInsumoOperario(id);
        }

        try {
            const response = await deleteOneInsumo;
            console.log(response);
            await getIngresoInsumosAll();
            return { status: response.status, message: response.data.message };
        } catch (error) {
            await getIngresoInsumosAll();
            console.error("Error:", error);
            if (error.response) {
                return { status: error.response.status, message: error.response.data.message };
            } else {
                return { status: 500, message: `Error desconocido. Vuelva a intentarlo.` };
            }
        } finally {
            setTableLoading(false);
        }
    }

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    //SweetAlert2
    const showSwal = async (id) => {
        Swal.fire({
            title: "¿Estás seguro de eliminar este ingreso de insumo?",
            text: "¡No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc2626",
            cancelButtonColor: "#71717a",
            confirmButtonText: "Sí, eliminar"
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    let mensaje = await deleteOneIngresoInsumo(id);

                    let title = "";
                    let icon = "";
                    let text = mensaje.message;

                    console.log(mensaje);

                    if (mensaje.status === 200) {
                        title = "¡Eliminado!";
                        icon = "success";
                    } else {
                        title = "Error";
                        icon = "error";
                    }

                    notify(text, icon);
                } catch (error) {
                    console.error("Error eliminando el insumo:", error);
                    setTableLoading(false);
                    notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde.`, "error");
                }
            }
        });
    }

    // COLUMNAS DE LA TABLA

    const columns = useMemo(
        () => [
            // {
            //    accessorFn: (row) => row.id,
            //    id: "id", //simple recommended way to define a column
            //    header: "ID",
            //    size: 10
            // /* muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
            // Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render */
            // },
            {
                id: "#",
                header: "Nro.",
                size: 1,
                Cell: ({ row }) => {
                    return row.index + 1;
                }
            },
            {
                accessorFn: (row) => (row.insumo ? row.insumo.codigo : '-'),
                id: "codigo",
                header: "Código",
                size: 5
            },
            {
                accessorFn: (row) => (row.insumo ? row.insumo.nombre : '-'),
                id: "nombre",
                header: "Insumo",
                size: 5
            },
            {
                accessorFn: (row) => (row.insumo ? row.insumo.color : '-'),
                id: "color",
                header: "Color",
                size: 1,
            },
            {
                accessorFn: (row) => row.precio_unitario,
                id: "precio_unitario",
                header: "Precio Unitario S/.",
                size: 1,
            },
            {
                accessorFn: (row) => row.cantidad,
                id: "cantidad",
                header: "Cantidad",
                size: 1,
            },
            {
                accessorFn: (row) => row.precio_total,
                id: "precio_total",
                header: "Precio Total",
                size: 2
            },
            {
                accessorFn: (row) => new Date(row.fecha_ingreso),
                id: "fecha_ingreso",
                header: "Fecha de Ingreso",
                filterVariant: 'datetime-range',
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                },
            },
            {
                accessorFn: (row) => (row.usuario ? row.usuario.name : '-'),
                id: "name",
                header: "Ingresado por:",
                size: 2
            },
            {
                accessorFn: (row) => (row.usuario ? row.usuario.email : '-'),
                id: "email",
                header: "Correo:",
                size: 2
            },
            /* {
                accessorFn: (row) => (row.usuario ? row.usuario.role.rol : '-'),
                id: "rol",
                header: "Rol:",
                size: 2,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() == "Administrador"
                                    ? theme.palette.success.dark
                                    : cell.getValue() == "Gerente"
                                        ? theme.palette.info.dark
                                        : theme.palette.secondary.dark,
                            borderRadius: '0.25rem',
                            color: "white",
                            p: "0.25rem"
                        })}
                    >
                        {cell.getValue() == "Administrador" ? "Admin" : cell.getValue()}
                    </Box>
                )
            }, */
        ],
        []
    );

    const data = (ingresoInsumos ? ingresoInsumos : []);

    const mostrarAccionesRol = () => {
        const rol = getRol();
        return rol === "Administrador" || rol === "Operario";
    };

    const table = useMaterialReactTable({
        columns,
        data,

        enableExpandAll: false, //disable expand all button
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor:
                    theme.palette.mode === 'dark'
                        ? 'rgba(255,210,244,0.1)'
                        : 'rgba(0,0,0,0.1)',
            }),
        }),
        //custom expand button rotation
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
            },
        }),

        renderDetailPanel: ({ row }) =>
            row.original.usuario ? (
                <Box
                    sx={{
                        display: '',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography><span className='font-bold'>Ingresado por:</span></Typography>
                    <Typography><span className='font-bold'>- Nombre:</span> {row.original.usuario.name}</Typography>
                    <Typography><span className='font-bold'>- Correo:</span> {row.original.usuario.email}</Typography>
                    <Typography><span className='font-bold'>- Rol:</span> {row.original.usuario.role.rol}</Typography>
                </Box>
            ) : null,

        initialState: {
            showColumnFilters: false,
            density: 'compact',
            columnVisibility: {
                email: false,
                rol: false,
            }
        },

        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,

        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount,

        state: {
            columnFilters,
            globalFilter,
            isLoading: tableLoading,
            pagination,
            sorting,
        },

        localization: MRT_Localization_ES,
        muiSearchTextFieldProps: {
            placeholder: 'Buscar todos los ingresos de insumos',
            sx: { minWidth: '300px' },
            variant: 'outlined',
        },
        enableRowActions: mostrarAccionesRol(),
        positionActionsColumn: mostrarAccionesRol() ? 'last' : undefined,

        renderRowActions: ({ row }) => (
            <Box>
                {getRol() === "Administrador" || getRol() === "Operario" ?
                    <IconButton title='Editar' onClick={() => {
                        setEditId(row.original.id);
                        setOpenEdit(true);
                        console.log("fila", row.original.id);
                    }} color="primary">
                        <EditIcon />
                    </IconButton>
                    : null}

                {getRol() === "Administrador" || getRol() === "Operario" ?
                    <IconButton title='Eliminar' onClick={() => {
                        showSwal(row.original.id);
                        /* console.log("fila id: ", row.original.id); */
                    }}
                        color="error">
                        <DeleteIcon />
                    </IconButton>
                    : null}

            </Box>
        ),
    });

    return (
        <div>

            <div className='flex justify-start mb-5 flex-wrap'>
                <button className='bg-[#339A5B] hover:bg-[#277545] rounded shadow-md text-white p-3 flex items-center'
                    onClick={() => { setOpenAdd(true); console.log(openAdd); }}
                >
                    <FaPlus className='me-3' />
                    Añadir Ingreso de Insumo
                </button>
            </div>

            <div className='mb-20'>

                <MaterialReactTable
                    table={table}
                />

            </div>

            <ModalAnadirIngresoInsumo
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                updateInsumoList={updateInsumoList}
            />

            <ModalEditarIngresoInsumo
                open={openEdit}
                editId={editId}
                onClose={() => setOpenEdit(false)}
                updateInsumoList={updateInsumoList}
            />

        </div>
    )
}
