import React from 'react'

export default function FooterNav() {
    return (
        <footer className='relative bottom-0 w-full'>
            <div className='flex flex-col justify-evenly items-stretch bg-black pt-8'>
                <div className='flex justify-around flex-wrap' style={{ fontSize: '14px' }}>
                    <div className='text-white w-72 my-6'>
                        <p>Jossmary es una empresa textil especializada en la creación de prendas de alta calidad y en brindar servicios personalizados. Ofrecemos diseño, confección y producción de ropa para marcas y empresas, asegurando precisión en cada detalle. Con experiencia en moda y textiles, combinamos innovación y técnicas tradicionales para ofrecer productos que destacan por su estilo y durabilidad. Nuestro compromiso es ofrecer soluciones que se adapten a las necesidades específicas de cada cliente, garantizando satisfacción y resultados excepcionales en todas nuestras prendas y servicios textiles.</p>
                    </div>

                    <div className='text-white w-72 my-6'>
                    ¿Sabías que en Jossmary utilizamos telas de origen sostenible y procesos ecoamigables para la confección de nuestras prendas, contribuyendo al cuidado del medio ambiente?
                    </div>

                    <div className='text-white w-72 my-6'>
                        <p>Contacto: </p>
                        <p>Celular: 999-888-000</p>
                        <p>Email: jossmary@gmail.com</p>
                    </div>

                </div>

                <div className='flex mt-8 justify-center items-center text-white' style={{fontSize: '12px'}}>Copyright 2024 © Jossmary Web - Todos los derechos reservados,</div>
            </div>
        </footer>
    )
}
