import React from 'react';
import BarraNavegacion from '../../components/navbarCliente/BarraNavegacion';
import FooterNav from '../../components/navbarCliente/FooterNav';

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";


export default function PaginaPrincipalCli() {

  const images = [
    {
      original: "/img/slides/slide-1.webp",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "/img/slides/slide-2.jpg",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "/img/slides/slide-3.jpg",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];

  return (
    <div className='min-h-screen bg-gray-100 flex flex-col'>
        
        <BarraNavegacion/>
        
        <div className="flex justify-center items-center flex-col text-white bg-green-700 w-full h-24">
          <h1 style={{ fontSize: '24px' }}>Diseño de Website para Empresa Textil</h1>
          <h2 style={{ fontSize: '16px' }} className='italic'>Jossmary</h2>
        </div>

        <div style={{ width: '65vw', margin: 'auto', marginTop: '10px', marginBottom: '10px' }}>
          <ImageGallery
          items = {images}
          showPlayButton={false}
          // showFullscreenButton={false}
          // showThumbnails={true}
          // showBullets={true}
          // autoPlay={true}
          
          slideInterval={3000}
          slideDuration={1000}
          showThumbnails={false}
          />
        </div>

        <div className='flex flex-col px-44 mb-10'>
          <h1 classname='font-bold' style={{ fontSize: '24px' }}>Empresa Textil Jossmary</h1>
          <p className='mt-3'>Tejemos calidad en cada hilo,
Diseñando para ti productos únicos y duraderos.
Ofrecemos soluciones textiles que transforman tu estilo,
Con el mejor servicio y atención personalizada.
Viste tu vida con la elegancia y confianza de Jossmary.</p>
        </div>
        
        <FooterNav/>
        
    </div>
  )
}
