import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import { Link, useNavigate } from 'react-router-dom';
import Config from '../../Config';
import validator from 'validator';

import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BarraNavegacion from '../../components/navbarCliente/BarraNavegacion';




export default function Login() {

    const { setToken, getToken, getRol } = AuthUser();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "SDI Jossmary | Iniciar Sesión";
        if (getToken()) {
            const rol = getRol();
            if (rol === "Administrador" || rol === "Gerente") {
                navigate("/usuario/dashboard");
            } else if (rol === "Operario") {
                navigate("/usuario/lista-insumos-principal");
            } else if (rol === "Almacenero") {
                navigate("/usuario/lista-insumos-ingresos");
            }
        }
    }, []);

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const submitLogin = async (e) => {
        e.preventDefault();

        if (!validator.isEmail(email)) {
            setEmailError('Por favor ingrese un correo electrónico válido.')
        } else {
            setEmailError('');
        }

        if (validator.isEmpty(password)) {
            setPasswordError('Ingrese una contraseña.')
        } else {
            setPasswordError('');
        }

        //Si está todo bien, se continua con enviar al formulario
        setMessage('');

        if (email && password && validator.isEmail(email)) {

            setLoading(true);

            //Development
            //http://localhost:8000/

            //Producción
            // https://backend-proyecto.inkadeveloper.com/

            await Config.getLogin({ email, password })
                .then(({ data }) => {
                    console.log(data);
                    if (data.status === "success") {
                        notify();
                        console.log(data);
                        setToken(
                            data.user,
                            data.access_token,
                            data.user.role.rol
                        );
                        const rol = data.user.role.rol;
                        if (rol === "Administrador" || rol === "Gerente") {
                            navigate("/usuario/dashboard");
                        } else if (rol === "Operario") {
                            navigate("/usuario/lista-insumos-principal");
                        } else if (rol === "Almacenero") {
                            navigate("/usuario/lista-insumos-ingresos");
                        }
                        console.log("El rol es: ", getRol(), " y tipo ", typeof getRol());
                        setMessage('Inicio de sesión exitoso.');
                    } else {
                        setMessage(data.message);
                    }
                }).catch(error => {
                    setMessage('Ha ocurrido un error. Vuelva a intentar más tarde.')
                }).finally(() => {
                    setLoading(false);
                });;
        }
    }

    const notify = () => toast.success('Inicio de Sesión exitoso.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    return (
        <>
            <div className='h-screen bg-gray-100 flex flex-col'>
                <BarraNavegacion></BarraNavegacion>
                <div className='flex-grow flex items-center justify-center'>
                    <div className='bg-white w-96 p-6 rounded-md shadow-lg'>
                        <div className='text-center text-3xl mb-2 text-gray-500 font-semibold'>
                            <h1>Iniciar Sesión</h1>
                        </div>

                        <div className='flex items-center justify-center mb-4'>
                            <img src='/img/login_user.webp' alt='logo-inicio' className='h-32 App-logo' />
                        </div>

                        {message && <p className='text-center text-slate-700 mt-3 bg-red-100 rounded-md py-2 border border-red-200 mb-2'>{message}</p>}

                        <label className="text-gray-500" htmlFor='email'>Correo Electrónico</label>

                        <form>
                            <input id="email"
                                className={`w-full py-2 bg-gray-50 text-gray-500 px-2 outline-none mb-2 rounded-md text-sm
                                placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                border ${emailError ? 'border-red-500' : 'border-slate-300'}`}
                                type="email"
                                value={email}
                                onChange={handleEmail}
                            />
                            {emailError && <p className="text-red-500 text-xs -mt-1">{emailError}</p>}

                            <label className="text-gray-500" htmlFor="password">Contraseña</label>
                            <input id="password"
                                className={`w-full py-2 bg-gray-50 text-gray-500 px-2 outline-none mb-2 rounded-md text-sm
                                placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                border ${passwordError ? 'border-red-500' : 'border-slate-300'}`}
                                type="password"
                                value={password}
                                onChange={handlePassword}
                            />

                            {passwordError && <p className="text-red-500 text-xs -mt-1">{passwordError}</p>}

                            <button type="submit"
                                disabled={loading} // Desactiva el botón mientras se carga
                                className={`bg-green-600 w-full
                        text-white py-2 rounded-md hover:bg-green-700 mt-4
                        transition-colors font-semibold leading-6
                        focus:ring-4 focus:outline-none focus:ring-green-600 mx-auto block ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
                                onClick={submitLogin}
                            >
                                {loading ?
                                    <div className='flex items-center justify-center'>
                                        <RotatingLines
                                            strokeColor="white"
                                            strokeWidth='3'
                                            width='22'
                                            radius="5"
                                            ariaLabel="lines-loading"
                                        />
                                    </div>
                                    :
                                    'Iniciar Sesión'}
                            </button>
                        </form>

                        <hr className='mt-5 h-0.5 border-none bg-gray-200'></hr>

                        <div className='text-center mt-2'>
                            <p className='text-gray-400 text-sm'>¿Olvidaste tu contraseña? <Link to='/recuperar-password' className='text-blue-400 underline cursor-pointer'>Recupérala</Link> </p>
                        </div>
                    </div>
                </div>

                <footer className='text-center'>
                    <hr className='border-none bg-gray-300' style={{ height: '0.5px' }}></hr>
                    <p className='my-4 text-slate-500' style={{ fontSize: '13px' }}>© 2024 Sistema de Inventariado | Jossmary. Todos los derechos reservados. </p>
                </footer>
            </div>
        </>
    )
}
