import React, {useEffect, useState, useCallback } from 'react';
import AuthUser from './AuthUser';
import { Navigate, Outlet } from 'react-router-dom';

import Config from '../../Config';

import { jwtDecode } from "jwt-decode";

import { toast } from 'react-toastify';

export default function ProtectedRoutes() {

  const { getToken, setRefreshToken } = AuthUser();
  const [redirect, setRedirect] = useState(false);

  const notify = (message, type) => {
    toast[type](message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const refreshToken = useCallback(async () => {    
    try {
      const response = await Config.refreshToken();
      setRefreshToken(response.data.user, response.data.access_token, response.data.user.role.rol); // Llama a la función para actualizar el token en localStorage y en el estado local
    } catch (error) {
      // Manejo de errores
      console.error('Error refreshing token:', error);
      notify("Sesión expirada. Inicie sesión de nuevo.", "error");
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('rol');
      localStorage.removeItem('token_expiration');
      setRedirect(true);
      window.location.replace('/');
      //return <Navigate to={'/'} />;
    }
  }, [setRefreshToken]);

  //Función para chequear y refrescar token
  const checkTokenExpirationAndRefresh = useCallback(async () => {
    const token = getToken();
    const tokenExpiration = localStorage.getItem('token_expiration');
    const currentTime = new Date().getTime();

    console.log("Hora actual: ",currentTime);
    console.log("Hora del token expira: ",tokenExpiration);
    console.log("Resta de token y hora actual : ",(tokenExpiration - currentTime));

    if (token && tokenExpiration) {
      // Si el token expirará en menos de 15 minutos, refrescarlo
      if (tokenExpiration - currentTime < 20 * 60 * 1000) {
        await refreshToken();
      }
    } 
  }, [getToken, refreshToken]);

  useEffect(() => {
    // Llama refreshToken cada 30 minutos, puede ser mejor ponerlo cada 10 minutos (quizá)
    const interval = setInterval(refreshToken, 15 * 60 * 1000); 
    //checkTokenExpirationAndRefresh();

    //Llamar a la función para compara y refrescar
    //Esto funciona al entrar a la página luego de cerrarla o dar F5
    //checkTokenExpirationAndRefresh();
    checkTokenExpirationAndRefresh();
    
    //const interval = setInterval(refreshToken, 10 * 1000); // Llama refreshToken cada 10 segundos
    return () => clearInterval(interval);
  }, [checkTokenExpirationAndRefresh, refreshToken]);

  if (!getToken() || redirect) {
    return <Navigate to={'/'} />
  }

  //Esto se puede ejecutar luego de cambiar de una pantalla a otra
  if (getToken()) {
    const decodedToken = jwtDecode(getToken());
    //console.log("Decoded Token", decodedToken);
    let currentDate = new Date();

    console.log("Token: "+getToken());
    localStorage.setItem('token_expiration', decodedToken.exp * 1000);
    
    // JWT exp está en segundos
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expirado.");
      // Navegar a la página de inicio de sesión cuando el token ha expirado
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('rol');
      localStorage.removeItem('token_expiration');
      notify("Su sesión ha expirado.", "error");
      return <Navigate to={'/'} />
    } else {
      //console.log("Token válido");
    }
  } else {
    //console.log("No se encontró ningún token en localStorage.");
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('rol');
    localStorage.removeItem('token_expiration');
    // Navegar a la página de inicio de sesión cuando no hay ningún token
    notify("No tiene permisos para ingresar al sistema.", "error");
    return <Navigate to={'/'} />
  }

  

  return (
    <Outlet />
  )
}
