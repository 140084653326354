import React, { useEffect, useState, useRef } from 'react'
import Config from '../Config';
import AuthUser from '../pages/pagesAuth/AuthUser';

import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';

import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

import { useMs } from '../context/MsContext';

import { AiFillDashboard } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { RiUserSmileFill } from "react-icons/ri";
import { MdInventory } from "react-icons/md";
import { FaTruck } from "react-icons/fa";
import { FaBoxesPacking } from "react-icons/fa6";
import { GiClothes } from "react-icons/gi";
import { GiSewingMachine } from "react-icons/gi";
import { HiBars3 } from "react-icons/hi2";


/* import { jwtDecode } from "jwt-decode"; */

export default function Navbar() {

  const { getLogout, getUser, getRol, getToken } = AuthUser();

  const [open, setOpen] = useState(false);

  const [rotar, setRotar] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(false);

  const [flechita, setFlechita] = useState(false);
  const { ms, changeMs } = useMs(); // Obtener el ms y la función para cambiarlo desde el contexto

  const dropdownRef = useRef(null);

  function getSize() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', getSize);

    if (width <= 768) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }

    /* console.log("Token: ", jwtDecode(getToken())); */

    return () => {
      window.removeEventListener('resize', getSize);
    };
  }, [width]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setOpen(false);
        setRotar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const logoutUser = () => {
    //Config.getLogout('/logout')
    setOpen(false);
    Config.getLogout()
      .then(response => {
        console.log(response);
        getLogout();
      }).catch(error => {
        console.error("Logout error PRUEBA:", error);
      })
  }

  const onHandleArrowNav = () => {
    if (flechita) {
      setFlechita(false);
      changeMs(false);
    } else {
      setFlechita(true);
      changeMs(true);
    }
  }



  const onHandleRotate = () => {
    if (rotar) {
      setRotar(false);
    } else {
      setRotar(true);
    }
  }

  const onHandleOpen = () => {
    if (open) {
      setOpen(false);
      setRotar(false);
    } else {
      setOpen(true);
    }
  }

  function DropdownItem(props) {
    return (
      <li className='dropdownItem'>
        {props.tipo == "redirigir" ?
          <NavLink to={props.route} onClick={props.accion}>{props.text}</NavLink>
          : <a onClick={props.accion}>{props.text}</a>}
      </li>
    );
  }

  return (
    <>
      <div className={`bg-white h-screen 
      fixed overflow-auto
      max-[768px]:w-32
      border-r-2 font-medium text-sm w-52 
      shadow-md
      ${ms ? '-left-full transition-all' : 'left-0 transition-all'}`}>

        <Sidebar width='208px' collapsedWidth='128px' collapsed={collapsed} backgroundColor='white' className='text-gray-600 select-none'>
          <Menu>

            {getRol() === "Administrador" || getRol() === "Gerente" ?
              <MenuItem icon={<AiFillDashboard size={18} />}
                component={<NavLink to="/usuario/dashboard" className="navbar-link" />}>
                Dashboard
              </MenuItem>
              : null}


            {getRol() === "Administrador" || getRol() === "Gerente" ?

              <>

                <MenuItem icon={<RiUserSmileFill size={18} />}
                  component={<NavLink to="/usuario/lista-clientes" className="navbar-link" />}>
                  Clientes
                </MenuItem>

                <MenuItem icon={<FaTruck size={18} />}
                  component={<NavLink to="/usuario/lista-proveedores" className="navbar-link" />}>
                  Proveedores
                </MenuItem>

              </>

              : ''}

            {(getRol() == "Administrador" || getRol() == "Operario" || getRol() == "Almacenero" || getRol() == "Gerente") ?
              <SubMenu icon={<FaBoxesPacking size={18} />} label="Insumos">

                {(getRol() == "Administrador" || getRol() == "Operario") ?

                  <MenuItem component={<NavLink to="/usuario/lista-insumos-principal" className="navbar-sublink" />}>
                    Ver Insumos (Principal)
                  </MenuItem>

                  : null}

                {(getRol() == "Administrador" || getRol() == "Operario" || getRol() == "Almacenero") ?

                  <>

                    <MenuItem component={<NavLink to="/usuario/lista-insumos-ingresos" className="navbar-sublink" />}>
                      Ver Insumos (Ingresos)
                    </MenuItem>

                    <MenuItem component={<NavLink to="/usuario/lista-insumos-salidas" className="navbar-sublink" />}>
                      Ver Insumos (Salida)
                    </MenuItem>


                  </>

                  : null}

                {(getRol() === "Administrador" || getRol() === "Gerente" || getRol() == "Operario" || getRol() == "Almacenero") ?

                  <MenuItem component={<NavLink to="/usuario/lista-insumos-kardex" className="navbar-sublink" />}>
                    Ver Kardex (Insumos)
                  </MenuItem>

                  : null}


                {(getRol() == "Administrador" || getRol() == "Operario") ?

                  <>
                    <MenuItem
                      component={<NavLink to="/usuario/lista-categorias-insumos" className="navbar-sublink" />}>
                      Ver Categorías (Insumos)
                    </MenuItem>

                    <MenuItem
                      component={<NavLink to="/usuario/lista-marcas-insumos" className="navbar-sublink" />}>
                      Ver Marcas (Insumos)
                    </MenuItem>
                  </>

                  : null}


              </SubMenu>
              : ''}

            {(getRol() == "Administrador" || getRol() == "Operario" || getRol() == "Almacenero" || getRol() === "Gerente") ?
              <SubMenu icon={<GiClothes size={18} />} label="Productos">

                {(getRol() == "Administrador" || getRol() == "Operario") ?

                  <MenuItem component={<NavLink to="/usuario/lista-productos-principal" className="navbar-sublink" />}>
                    Ver Productos (Principal)
                  </MenuItem>

                  : null}

                {(getRol() == "Administrador" || getRol() == "Operario" || getRol() == "Almacenero") ?

                  <>

                    <MenuItem component={<NavLink to="/usuario/lista-productos-ingresos" className="navbar-sublink" />}>
                      Ver Productos (Ingresos)
                    </MenuItem>

                    <MenuItem component={<NavLink to="/usuario/lista-productos-salidas" className="navbar-sublink" />}>
                      Ver Productos (Salidas)
                    </MenuItem>


                  </>

                  : null}

                {(getRol() === "Administrador" || getRol() === "Gerente" || getRol() == "Operario" || getRol() == "Almacenero") ?


                  <MenuItem component={<NavLink to="/usuario/lista-productos-kardex" className="navbar-sublink" />}>
                    Ver Kardex (Productos)
                  </MenuItem>

                  : null}



                {(getRol() == "Administrador" || getRol() == "Operario") ?


                  <MenuItem
                    component={<NavLink to="/usuario/lista-categorias-productos" className="navbar-sublink" />}>
                    Ver categorías de Productos
                  </MenuItem>

                  : null}

              </SubMenu>
              : ''}


            {getRol() === "Administrador" || getRol() === "Operario" || getRol() === "Almacenero" || getRol() === "Gerente" ?
              <SubMenu icon={<GiSewingMachine size={18} />} label="Servicios">

                {(getRol() === "Administrador" || getRol() === "Operario") ?

                  <MenuItem component={<NavLink to="/usuario/lista-servicios-principal" className="navbar-sublink" />}>
                    Ver Servicios (Principal)
                  </MenuItem>

                  : null}

                {(getRol() === "Administrador" || getRol() === "Operario" || getRol() === "Almacenero") ?

                  <>

                    <MenuItem component={<NavLink to="/usuario/lista-servicios-ingresos" className="navbar-sublink" />}>
                      Ver Servicios (Ingresos)
                    </MenuItem>

                    <MenuItem component={<NavLink to="/usuario/lista-servicios-salidas" className="navbar-sublink" />}>
                      Ver Servicios (Salidas)
                    </MenuItem>


                  </>

                  : null}

                {(getRol() === "Administrador" || getRol() === "Operario" || getRol() === "Almacenero" || getRol() === "Gerente") ?
                  <MenuItem component={<NavLink to="/usuario/lista-servicios-kardex" className="navbar-sublink" />}>
                    Ver Kardex (Servicios)
                  </MenuItem>
                  : null}

                {(getRol() === "Administrador" || getRol() === "Operario") ?

                  <MenuItem component={<NavLink to="/usuario/lista-servicios-especificos" className="navbar-sublink" />}>
                    Servicios Específicos
                  </MenuItem>

                  : null}


              </SubMenu>
              : ''
            }



            {getRol() == "Administrador" ? <MenuItem icon={<FaUsers size={18} />}
              component={<NavLink className="navbar-link" to="/usuario/lista-usuarios" />}>
              Usuarios </MenuItem>
              : null
            }
          </Menu>
        </Sidebar>


      </div>

      <div className='w-full shadow-md bg-[#339A5B] fixed top-0 flex justify-between z-50
      max-[480px]:flex max-[480px]:flex-wrap max-[480px]:flex-col
      select-none'>

        <div className='flex justify-center items-center ps-3'>
          <img src='/img/logo-empresa.webp' alt='logo-empresa' style={{ width: '52px', height: '52px' }} />
          <p className='ms-4 text-white text-lg'>Panel {getRol()}</p>

          <div className='text-white ms-8 max-[470px]:pe-4'>
            <HiBars3 className='size-8 cursor-pointer' onClick={() => { onHandleArrowNav(); }} />
          </div>

        </div>

        <div className='h-16 flex justify-end items-center max-[480px]:justify-center px-10 max-[594px]:p-5 max-[470px]:p-1 select-text'>
          <div className='menu-container' ref={dropdownRef}>
            <div className='menu-trigger flex items-center' onClick={() => { setOpen(!open) }}>
              <button className='flex items-center' onClick={onHandleRotate}>
                <p className='me-4 text-white flex items-center justify-center'>
                  <div className='flex flex-col justify-center items-end'>
                    <span className='' style={{ fontSize: '14px' }}>{getUser().email}</span>
                    <span style={{ fontSize: '12px' }}>{getUser().role.rol}</span>
                  </div>
                </p>
                <img src='/img/user-icon.webp' alt="icono_usuario_nav" style={{ width: '42px', height: '42px' }} />
                  <img className={`ms-4 mt-1 dropdown-user-icono${!rotar ? '.rotado' : ''}`} src='/img/dropdown-icon.webp' style={{ width: '12px', height: '7px' }} alt='flecha_abajo' />
              </button>
            </div>

            <div className={`dropdown-menu ${open ? 'activo' : 'inactive'}`}>
              <ul>
                {/* <DropdownItem text={"Perfil"} accion={onHandleOpen} tipo={"redirigir"} route={"/usuario/perfil"} /> */}
                <DropdownItem text={"Cerrar Sesión"} accion={logoutUser} tipo={""} />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
