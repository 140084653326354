import React from 'react';
import { Link } from 'react-router-dom';

export default function BarraNavegacion() {
    return (
        <nav className='w-full'>
          <div className="flex items-center bg-white shadow-lg h-16 px-4 z-50">
            <Link to="/">
              <img src='/img/logo/logo_empresa_jossmary.webp' alt='logo-empresa' style={{ width: '46px', height: '54px' }}/>
            </Link>
    
            <div className="flex w-full justify-between items-center ms-16">
              <div className='flex gap-9 text-sm font-medium'>
                <Link to="/" className='text-green-600 hover:text-green-700 link-effect'>Inicio</Link>
                <Link to="/quienes-somos" className='text-green-600 hover:text-green-700 link-effect'>¿Quiénes somos?</Link>
                <Link to="/mapa-del-sitio" className='text-green-600 hover:text-green-700 link-effect'>Mapa del Sitio</Link>
                <Link to="/productos-joss" className='text-green-600 hover:text-green-700 link-effect'>Productos</Link>
                <Link to="/servicios-joss" className='text-green-600 hover:text-green-700 link-effect'>Servicios</Link>
              </div>
    
              <div className='text-sm font-medium me-12'>
                <Link to="/iniciar-sesion" className='text-green-600 hover:text-green-700 link-effect'>Iniciar Sesión</Link>
              </div>
            </div>
          </div>
        </nav>
      );
}
