import React from 'react';
import BarraNavegacion from '../../components/navbarCliente/BarraNavegacion';
import FooterNav from '../../components/navbarCliente/FooterNav';

export default function QuienesSomos() {
    return (
        <div className='min-h-screen bg-gray-100 flex flex-col'>

            <BarraNavegacion />

            <div className="flex justify-center items-center flex-col text-white bg-green-700 w-full h-24">
                <h1 style={{ fontSize: '24px' }}>Acerca de Nosotros</h1>
            </div>

            <div className='flex justify-center px-44 flex-wrap my-4'>
                <img className='shadow-lg' src='/img/quienesSomos/quienes-somos.jpg.webp' alt='logo-empresa'
                style={{ width: '700px', height: '600px' }}
                />
                <div className='py-0 px-5' style={{ width: '500px' }}>
                    <h3 className="font-semibold" style={{ fontSize: '18px' }}>Empresa Textil "Jossmary"</h3>
                    <p className=''>En Textil Jossmary, nos dedicamos a ofrecer productos y servicios textiles de la más alta calidad, con un firme compromiso hacia la excelencia y la innovación. Desde nuestros inicios, hemos trabajado con pasión para brindar soluciones que se adapten a las necesidades y gustos de nuestros clientes.

                        Nuestra empresa se destaca por su amplia gama de productos, que van desde telas sofisticadas hasta confecciones personalizadas. Utilizamos tecnología de punta y técnicas avanzadas para garantizar que cada artículo cumpla con los estándares más exigentes de durabilidad y diseño. En Textil Jossmary, no solo vendemos textiles, sino que también ofrecemos un servicio integral que incluye asesoramiento experto y soluciones a medida.</p>
                    
                    <p className='mt-4'>
                    Contamos con un equipo de profesionales altamente capacitados, dedicados a brindar una atención personalizada y a escuchar las necesidades de nuestros clientes. Creemos en la importancia de construir relaciones sólidas basadas en la confianza y la satisfacción mutua.

En nuestra empresa, la innovación es el motor que impulsa nuestro crecimiento. Estamos en constante búsqueda de nuevas tendencias y mejoras en nuestros procesos para ofrecer productos que marquen la diferencia. Cada proyecto que emprendemos es una oportunidad para demostrar nuestro compromiso con la calidad y la creatividad.
                    </p>
                </div>
            </div>

            <FooterNav />

        </div>
    )
}
