import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import 'moment/locale/es-mx';


export default function CalendarioProductos({ dataProductos }) {
    moment.locale("es-mx");
    moment.updateLocale("es-mx", {
        week: {
            dow: 1,
            doy: 4,
        },
    });

    const localizer = momentLocalizer(moment);

    const preprocessData = (data) => {
        return data.map(item => ({
            title: `${item.nombre_producto} - ${item.color} - ${item.talla} | Cantidad: ${item.quantity_general}`,
            start: new Date(item.fecha),
            end: new Date(item.fecha),
            es_ingreso: item.es_ingreso_producto,
        }));
    };

    const Calendario = ({ events }) => {
        const eventStyleGetter = (event) => {
            const backgroundColor = event.es_ingreso == 1 ? 'green' : 'red';
            const style = {
                backgroundColor,
                borderRadius: '0px',
                opacity: 0.8,
                color: 'white',
                border: '0px',
                /* display: 'block' */
            };
            return { style };
        };

        return (
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                eventPropGetter={eventStyleGetter}
                messages={{
                    date: 'Fecha',
                    time: 'Hora',
                    event: 'Información del Producto',
                    next: "sig",
                    previous: "ant",
                    today: "Hoy",
                    month: "Mes",
                    week: "Semana",
                    day: "Día",
                    noEventsInRange: 'No hay ingresos y/o salidas en este rango de fechas.',
                    showMore: (total, remainingEvents, events) => `+${total} más`,
                  }}
            />
        );
    };

    const events = preprocessData(dataProductos);

    return <Calendario events={events} />;
}
