import React, { useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Modal from '../../modals/Modal';

import { FaPlus } from "react-icons/fa";
import Config from '../../../Config';

import { toast } from 'react-toastify';
import InputForm from '../../inputs/InputForm';

export default function ModalAnadirCategoriaProducto({ open, onClose, updateCatProductoList }) {

    const { getRol } = AuthUser();

    const [nombre, setNombre] = useState('');    

    const [errors, setErrors] = useState({});
    const [loadingSubmit, setloadingSubmit] = useState(false);

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            setErrors({});
        };
    }, [open]);
    
    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const addNewCategoriaProducto = async (e) => {
        e.preventDefault();
        setloadingSubmit(true);
        setErrors({});

        const categoriaInsumoData = {
            nombre_categoria_producto: nombre,
        };

        try {
            let response;

            if (getRol() === "Administrador") {
                response = await Config.postGuardarCatProductoAdmin(categoriaInsumoData);
            }else if(getRol() === "Operario"){
                response = await Config.postGuardarCatProductoOperario(categoriaInsumoData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(response.data.message, "error");
                } else if (response.data.exito == 1) {
                    notify("La categoría de producto ha sido añadido correctamente.", "success");
                    setNombre('');
                    onClose();
                    updateCatProductoList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }

        } catch (error) {
            console.error("Error: ", error);
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
            updateCatProductoList();
        } finally {
            setloadingSubmit(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div style={{ marginLeft: '136px' }}><p key={index} className="text-red-500 text-sm -mt-3 mb-3">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    }

  return (
    <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Añadir</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Añadir Categoría de Producto</h3>

                <form>

                    <InputForm
                        id="nombre"
                        labelName="Cat. de Producto:"
                        inputType="text"
                        value={nombre}
                        funcion={(e) => setNombre(e.target.value)}
                        errors={errors.nombre_categoria_producto}
                        labelWidth="w-44"
                    />

                    {errors.nombre_categoria_producto && Array.isArray(errors.nombre_categoria_producto) && renderErrorMessages(errors.nombre_categoria_producto)}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingSubmit}
                            className={`flex items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-64
                            text-white bg-green-600 
                            me-3
                            max-[439px]:me-0
                            max-[469px]:m-0
                            ${loadingSubmit ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={addNewCategoriaProducto}
                            type="submit">

                            {loadingSubmit ?
                                <div className='flex items-center justify-center w-44'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <FaPlus className='me-3' /> Guardar Categoría Insumo
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            max-[426px]:mt-3
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
  )
}
