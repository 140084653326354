import React, { useMemo, useState, useEffect } from 'react'
import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Button } from '@mui/material';

import AuthUser from '../../pages/pagesAuth/AuthUser';

import Modal from '../../components/modals/Modal';
import Config from '../../Config';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import { FaPlus, FaFileCsv } from 'react-icons/fa';
import CsvDownloader from 'react-csv-downloader';
import UserPdf from '../createPdf/UserPdf';

import { FaCircle } from "react-icons/fa";
import ModalAnadirUsuario from '../forms/Users/ModalAnadirUsuario';
import ModalEditarUsuario from '../forms/Users/ModalEditarUsuario';
import UsersExcel from '../createExcel/UsersExcel/UsersExcel';

export default function UserTable() {

    const { getUser, getToken, getRol } = AuthUser();
    const [loadingEdition, setloadingEdition] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const [users, setUsers] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);

    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);



    //Modal
    const [open, setOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [editId, setEditId] = useState('');

    //Para actualizar luego de agregar o editar
    const updateUserList = async () => {
        await getUserAll();
    };

    //Editar
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [rol, setRol] = useState('');
    const [userActive, setUserActive] = useState('');
    const [selectedUserActive, setSelectedUserActive] = useState('');

    useEffect(() => {
        console.log("PAGINA INDEX:", pagination.pageIndex);
        console.log("PAGINA SIZE:", pagination.pageSize)
        getUserAll();
        console.log(users);
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        columnFilters,
        globalFilter,
        sorting
    ]);

    const getUserAll = async () => {
        setTableLoading(true);

        let getUsersList;

        if (getRol() === "Administrador") {
            getUsersList = Config.getUsersListAdmin(pagination.pageIndex, pagination.pageSize, globalFilter, columnFilters, sorting);
        } else if (getRol() === "Gerente") {
            getUsersList = Config.getUsersListGerente(pagination.pageIndex, pagination.pageSize, globalFilter, columnFilters, sorting);
        }

        try {
            const response = await getUsersList;
            /* console.log(response.data); */
            setUsers(response.data.data);
            setRowCount(response.data.total);
            /* console.log(response); */
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setTableLoading(false);
        }
    }

    const deleteOneUser = async (id) => {
        setTableLoading(true);
        let deleteOneUserList;

        if (getRol() == "Administrador") {
            deleteOneUserList = Config.deleteUserAdmin(id);
        } else if (getRol() == "Gerente") {
            deleteOneUserList = Config.deleteUserGerente(id);
        }

        try {
            const response = await deleteOneUserList;
            console.log(response);
            await getUserAll();
            return { status: response.status, message: response.data.message };
        } catch (error) {
            await getUserAll();
            showSwalMessageError();
            console.error("Error:", error);
            if (error.response) {
                return { status: error.response.status, message: error.response.data.message };
            } else {
                return { status: 500, message: "Error desconocido. Vuelva a intentarlo." };
            }
        }
    }

    const notify = (mensaje) => toast.success(mensaje, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    //SweetAlert2
    const [swalShown, setSwalShown] = useState(false)
    const showSwal = async (id) => {
        Swal.fire({
            title: "¿Estás seguro de eliminar este usuario?",
            text: "¡No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc2626",
            cancelButtonColor: "#71717a",
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    let mensaje = await deleteOneUser(id);

                    let title = "";
                    let icon = "";
                    let text = mensaje.message;

                    if (mensaje.status === 200) {
                        title = "¡Eliminado!";
                        icon = "success";
                    } else {
                        title = "Error";
                        icon = "error";
                    }

                    Swal.fire({
                        title: title,
                        text: text,
                        icon: icon,
                        confirmButtonColor: "#16a34a",
                    });
                } catch (error) {
                    console.error("Error eliminando el usuario:", error);
                    showSwalMessageError();
                } finally {
                    setTableLoading(false);
                }
            }
        }).finally(() => {
            setTableLoading(false);
        });
    }

    const showSwalMessageError = () => {
        Swal.fire({
            title: "Ha ocurrido un error",
            text: "Vuelva a intentarlo en unos minutos.",
            icon: "error"
        });
    }

    const cerrarModal = (e) => {
        e.preventDefault();
        setOpen(false);
        setOpenAdd(false);
    }

    /* const dateFormatter = (dateString) => {
        const parsedDate = new Date(dateString).toLocaleString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        return parsedDate;
    }; */

    // COLUMNAS DE LA TABLA

    const columns = useMemo(
        () => [
            /* {
                accessorKey: "id",
                header: "ID",
                size: 10
            }, */
            {
                id: "#",
                header: "Nro.",
                size: 1,
                Cell: ({ row }) => {
                    return row.index + 1;
                }
            },
            {
                accessorFn: (row) => row.name, //alternate way
                id: "name", //id required if you use accessorFn instead of accessorKey
                header: "Nombre Completo",
            }
            ,
            {
                accessorFn: (row) => row.email, //alternate way
                id: "email", //id required if you use accessorFn instead of accessorKey
                header: "Correo Electrónico",
                /* Header: <i style={{ color: "red" }}>Correo Electrónico</i> //optional custom markup */
            },
            {
                accessorFn: (row) => new Date(row.created_at), //alternate way
                id: "created_at", //id required if you use accessorFn instead of accessorKey
                header: "Fecha de Creación",
                filterVariant: 'datetime-range',
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                },
            },
            {
                accessorFn: (row) => new Date(row.updated_at), //alternate way
                id: "updated_at", //id required if you use accessorFn instead of accessorKey
                header: "Fecha de Actualización",
                filterVariant: 'datetime-range',
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                },
            },
            {
                accessorFn: (row) => row.role.rol, //alternate way
                id: "rol", //id required if you use accessorFn instead of accessorKey
                header: "Rol",
                size: 50,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() == "Administrador"
                                    ? theme.palette.success.dark
                                    : cell.getValue() == "Gerente"
                                        ? theme.palette.info.dark
                                        : theme.palette.secondary.dark,
                            borderRadius: '0.25rem',
                            color: "white",
                            p: "0.25rem"
                        })}
                    >
                        {cell.getValue() == "Administrador" ? "Admin" : cell.getValue()}
                    </Box>
                )
            },
            {
                accessorFn: (row) => row.is_active, //alternate way
                id: "is_active", //id required if you use accessorFn instead of accessorKey
                header: "Activo",
                size: 10,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            fontSize: "10px",
                            color: `${cell.getValue() == '1' ? 'green' : '#d63333'}`,
                        })}
                    >
                        <FaCircle />
                    </Box>
                ),
            },
        ],
        []
    );

    const data = (users ? users : []);

    const table = useMaterialReactTable({
        columns,
        data,

        initialState: {
            showColumnFilters: false,
            density: 'compact'
        },

        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,

        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount,

        state: {
            columnFilters,
            globalFilter,
            isLoading: tableLoading,
            pagination,
            sorting,
        },

        localization: MRT_Localization_ES,
        muiSearchTextFieldProps: {
            placeholder: 'Buscar todos los usuarios',
            sx: { minWidth: '300px' },
            variant: 'outlined',
        },
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => (
            <Box>
                <IconButton title='Editar' onClick={() => {
                    setEditId(row.original.id);
                    setOpenEdit(true);
                    console.log("fila", row.original.id);
                }} color="primary">
                    <EditIcon />
                </IconButton>

                <IconButton title='Eliminar' onClick={() => { showSwal(row.original.id); console.log("fila id: ", row.original.id); }} color="error">
                    <DeleteIcon />
                </IconButton>
            </Box>
        ),

        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                {/* <CsvDownloader
                    datas={
                        users
                    }
                    filename={`usuarios_datos_${new Date().toLocaleDateString()}`}
                    extension='.csv'
                    separator=';'
                    columns={columns}
                    className={`max-[495px]:ms-1 max-[467px]:ms-0 max-[459px]:mt-3 rounded`}
                >
                    <button
                        className='bg-white text-lime-600 
                        border rounded border-lime-600
                        hover:text-white hover:bg-lime-600
                        py-1 px-2
                        mb-1 -ms-2 
                        flex items-center'

                    >
                        <FaFileCsv className='me-3' />
                        Exportar CSV
                    </button>
                </CsvDownloader> */}
                <UsersExcel users={users}/>

                <UserPdf
                    /* roll={getRol()}
                    columns={columns} */
                    usersData={users}
                />
            </Box>
        ),
    });

    return (
        <div>

            <div className='flex justify-start mb-5 flex-wrap'>
                <button className='bg-[#339A5B] hover:bg-[#277545] rounded shadow-md text-white p-3 flex items-center'
                    onClick={() => setOpenAdd(true)}
                >
                    <FaPlus className='me-3' />
                    Añadir Usuario
                </button>
            </div>

            {/* <UserPdf
                roll={getRol()}
                columns={columns}
            /> */}

            <div className='mb-3'>

            </div>


            <MaterialReactTable
                table={table}
            />

            <ModalAnadirUsuario
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                updateUserList={updateUserList}
            />

            <ModalEditarUsuario
                open={openEdit}
                editId={editId}
                onClose={() => setOpenEdit(false)}
                updateUserList={updateUserList}
            />

        </div>
    )

}
