import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import Swal from 'sweetalert2';

import { RotatingLines } from 'react-loader-spinner';

import { FaFilePdf } from 'react-icons/fa';

export default function KardexServiciosPdf({ kardexServiciosData }) {

    const [loadingExportPdf, setLoadingExportPdf] = useState(false);

    const dateFormatter = (dateString) => {
        const parsedDate = new Date(dateString).toLocaleString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        return parsedDate;
    };

    const customDateFormatter = (date) => {
        const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'p. m.' : 'a. m.';
        return `${day} de ${month} del ${year} a las ${hours}:${minutes}:${seconds} ${ampm}`;
        //return `${day} de ${month} del ${year}, ${hours}:${minutes}:${seconds}`;
    };

    const handleGeneratePdf = async () => {
        try {
            setLoadingExportPdf(true);

            const data2 = kardexServiciosData;
            const currentDateTime = customDateFormatter(new Date());

            // Luego de obtener los datos actualizados, genera el PDF
            const doc = new jsPDF('landscape', 'mm', 'A4', 0);
            let imgData = '/img/logo/logo_empresa_jossmary.png';

            doc.addImage(imgData, 'PNG', 5, 1, 17, 27);
            const title1 = "Empresa Textil Jossmary";
            const title2 = "Reporte de Ingresos y Salidas de Servicios";
            const subtitule = `Generado el: ${currentDateTime}`;
            const padding = 10;            

            const addHeadersAndFooters = (data) => {                
                // Add logo
                doc.addImage(imgData, 'PNG', 5, 1, 17, 27);

                // Add title1
                doc.setTextColor('#339A5B').setFont(undefined, 'bold').setFontSize(12);
                let title1Width = doc.getTextWidth(title1);
                let title1Center = (doc.internal.pageSize.width / 2) - (title1Width / 2);
                doc.text(title1, title1Center, padding);

                // Add title2
                let title2Width = doc.getTextWidth(title2);
                let title2Center = (doc.internal.pageSize.width / 2) - (title2Width / 2);
                doc.text(title2, title2Center, padding + 6);

                // Add subtitule
                doc.setTextColor('#19763d').setFont(undefined, 'normal').setFontSize(9);
                doc.text(subtitule, doc.internal.pageSize.width - doc.getTextWidth(subtitule) - 10, padding - 6);
                

            };

            //const pageCount = Math.ceil(data2.length / 10);

            doc.autoTable({
                head: [['#', 'Movimiento', 'Código', 'Servicio', 'Descripción',
                    'Email R. Merma',
                    'Can. Ingreso', 'Can. Merma', 'Can. Final', 'P. Total S/.', 'Fecha de Ingreso', 
                    'N. Ingreso', 'Email Ingreso', 'Rol Ingreso', 
                     'Can. Salida', 'Fecha Salida', 
                     'N. Salida', 'Email Salida', 'Rol Salida']],
                body: data2.map((val, i) => [i + 1,
                val.es_ingreso_servicio == 1 ? 'Ingreso' : 'Salida',
                val.codigo,
                val.nombre_servicio,
                val.descripcion,
                val.email_responsable,
                val.cantidad_ingreso_serv,
                val.merma_servicios,
                val.cantidad_final_serv,
                val.precio_total,
                val.fecha_ingreso_serv ? dateFormatter(val.fecha_ingreso_serv) : null,
                val.nombre_ingreso,
                val.email_ingreso,
                val.rol_ingreso,
                val.cantidad_salida_serv,
                val.fecha_salida_serv ? dateFormatter(val.fecha_salida_serv) : null,
                val.nombre_salida,
                val.email_salida,
                val.rol_salida,
                //val.estado_proveedor === 1 ? 'Activo' : 'Inactivo'
                ]),

                headStyles: {
                    fillColor: "#339A5B",
                    textColor: "white"
                },
                margin: { top: 29, right: 5, bottom: 10, left: 5 },
                styles: {
                    fontSize: 8 // Reduciendo el tamaño del texto de la tabla
                },
                didDrawPage: function (data) {
                    addHeadersAndFooters(data);
                },

                didParseCell: function (data) {
                    console.log(data);
                    if(data.section === 'body' && data.column.index == 1) {
                        if(data.cell.raw === 'Ingreso') {
                            data.cell.styles.fillColor = [65, 163, 73];
                            data.cell.styles.textColor = "white";
                        } else if (data.cell.raw === 'Salida') {
                            data.cell.styles.fillColor = [201, 66, 56];
                            data.cell.styles.textColor = "white";
                        }
                    }
                }
                /* didDrawPage: function (data) {
                    // Footer
                    const pageNumber = doc.internal.getNumberOfPages();
                    const str = `Página ${pageNumber} de ${pageCount}`;
                    doc.setFontSize(10);
                    doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
                } */
            });

            setTimeout(() => {
                doc.save(`pdf_servicios_kardex_${new Date().toLocaleDateString()}`);
            }, 1000);

            /* if (status === 200) {
                showSwalMessage('Éxito', 'Usuarios exportados con éxito.', 'success');
            } */
        } catch (error) {
            /* console.error("Error generando PDF:", error);
            console.log(error); */
            showSwalMessage('Error', 'Ha ocurrido un error. Vuelva a intentarlo.', 'error');
        } finally {
            setTimeout(() => {
                setLoadingExportPdf(false);
            }, 1000);
        }
    }

    const showSwalMessage = (titulo, texto, icono) => {
        Swal.fire({
            title: titulo,
            text: texto,
            icon: icono,
            confirmButtonColor: "#339A5B",
        });
    }

  return (
    <div className='flex justify-start flex-col mb-4 flex-wrap relative max-w-96'>

            <div className='flex'>

                <button
                    disabled={loadingExportPdf}
                    className={`max-[495px]:ms-1 max-[467px]:ms-0 max-[315px]:mt-3 px-2 py-1 flex items-center
                        ${loadingExportPdf ? 'cursor-not-allowed opacity-50 bg-red-600 text-white border border-red-600 rounded' : 
                 `bg-white rounded text-red-600 
                 border border-red-600
                 hover:text-white hover:bg-red-600
                 `}
                 `}
                    onClick={handleGeneratePdf}
                >
                    {loadingExportPdf ?
                        <div className='flex items-center justify-center'>
                            <RotatingLines
                                strokeColor="white"
                                strokeWidth='3'
                                width='22'
                                radius="5"
                                ariaLabel="lines-loading"
                            />
                            <p className='ms-3'>Exportando...</p>
                        </div>
                        : <>
                            <FaFilePdf className='me-3' /> Exportar PDF
                        </>
                    }
                </button>


            </div>
        </div>
  )
}
