import React, { useEffect, useState } from 'react';

import Config from '../../../Config';
import AuthUser from '../../../pages/pagesAuth/AuthUser';
import InputForm from '../../inputs/InputForm';

import Modal from '../../modals/Modal';

import { RotatingLines, TailSpin } from 'react-loader-spinner';
import { MdEditDocument } from "react-icons/md";

import { toast } from 'react-toastify';

import AsyncSelect from 'react-select/async';

export default function ModalEditarSalidaInsumo({ open, onClose, updateSalidaInsumoList, editId }) {

    const { getRol, getToken } = AuthUser();

    const id = editId;

    const [userToken, setUserToken] = useState('');
    const [insumoId, setInsumoId] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [fechaSalida, setFechaSalida] = useState('');

    // Función para formatear la fecha
    const formatFechaForDB = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    //Variable para restringir fecha
    const now = new Date();
    const now_insumo = formatFechaForDB(now);

    //Mostrar datos actuales de ingreso, salida y saldo
    const [labelIngreso, setLabelIngreso] = useState('');
    const [labelSalida, setLabelSalida] = useState('');
    const [labelSaldo, setLabelSaldo] = useState('');

    const [limiteSalida, setLimiteSalida] = useState('');

    const [errors, setErrors] = useState({});

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            cargarSalidaInsumo();
            setUserToken(getToken());
            setZIndex(9999);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const loadInsumosOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectInsumosAdmin(globalFilter);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectInsumosOperario(globalFilter);
            }

            const arrayInsumos = response.data;
            if (arrayInsumos.exito == 0) {
                return [];
            }

            return arrayInsumos.map(insumo => ({
                value: insumo.id,
                label: `${insumo.id} - ${insumo.codigo} - ${insumo.nombre} - ${insumo.color}`,
                ingreso: insumo.ingreso,
                salida: insumo.salida,
                saldo: insumo.saldo,
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar la lista de insumos. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const cargarSalidaInsumo = async () => {
        setLoadingData(true);
        setErrors({});

        try {

            let response;

            if (getRol() === "Administrador") {
                response = await Config.getOneSalidaInsumoAdmin(id);
            } else if (getRol() === "Operario") {
                response = await Config.getOneSalidaInsumoOperario(id);
            }/*  else if (getRol() === "Almacenero") {
                response = await Config.getOneIngresoInsumoAlmacenero(id);
            } */

            console.log(response);

            if (response.data.exito == 0) {
                notify(`Error: ${response.data.message}`, "error");
            } else {
                setInsumoId(response.data.insumo_salida);
                setCantidad(response.data.cantidad);
                setFechaSalida(response.data.fecha_salida);

                setLabelIngreso(response.data.insumo_salida.ingreso);
                setLabelSalida(response.data.insumo_salida.salida);
                setLabelSaldo(response.data.insumo_salida.saldo);

                setLimiteSalida(response.data.cantidad+response.data.insumo_salida.saldo);
            }

        } catch (error) {
            console.error(error);
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        } finally {
            setLoadingData(false);
        };
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadingButton(true);

        try {

            const salidaInsumoData = {
                token_usuario: userToken,
                insumo_id: (insumoId.id ? insumoId.id : insumoId.value),
                cantidad: cantidad,
                fecha_salida: fechaSalida
            };

            console.log("Datos de salida de insumos: ", salidaInsumoData);

            let response;

            if (getRol() === "Administrador") {
                response = await Config.putUpdateSalidaInsumoAdmin(id, salidaInsumoData);
            } else if (getRol() === "Operario") {
                response = await Config.putUpdateSalidaInsumoOperario(id, salidaInsumoData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(`Error: ${response.data.message}`, "error");
                } else if (response.data.exito == 1) {
                    notify("La salida de insumo ha sido editado correctamente.", "success");
                    setUserToken('');
                    setInsumoId('');
                    setCantidad('');
                    setFechaSalida('');
                    onClose();
                    updateSalidaInsumoList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }


        } catch (error) {
            console.log(error);
            updateSalidaInsumoList();
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
            setLoadingButton(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div style={{ marginLeft: '136px' }}><p key={index} className="text-red-500 text-sm -mt-3 mb-3 ms-1">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Editar</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Editar Salida Insumo</h3>

                <form>

                    {!loadingData ?

                        <>

                            {errors.token_usuario && Array.isArray(errors.token_usuario) && renderErrorMessages(errors.token_usuario)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='categoria_id' className='w-56 flex items-center font-normal text-[#454545]'>Insumo: </label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadInsumosOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.insumo_id)}
                                    id="insumo_id"
                                    /* value={insumoId} */
                                    defaultValue={{ id: insumoId.id, label: `${insumoId.id} - ${insumoId.codigo} - ${insumoId.nombre} - ${insumoId.color}` }}
                                    onChange={(option, id) => {
                                        setLabelIngreso('');
                                        setLabelSalida('');
                                        setLabelSaldo('');

                                        setInsumoId(option);
                                        console.log(option, id);
                                        setLabelIngreso(option.ingreso);
                                        setLabelSalida(option.salida);
                                        setLabelSaldo(option.saldo);
                                    }}
                                    placeholder="Seleccionar insumo..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.insumo_id && Array.isArray(errors.insumo_id) && renderErrorMessages(errors.insumo_id)}

                            <InputForm
                                id="cantidad"
                                labelName="Cantidad:"
                                inputType="number"
                                value={cantidad}
                                funcion={(e) => {
                                    setCantidad(e.target.value);
                                    //setLimiteSalida(limiteSalida);
                                }}
                                errors={errors.cantidad}
                                labelWidth="w-56"
                            />


                            {errors.cantidad && Array.isArray(errors.cantidad) && renderErrorMessages(errors.cantidad)}

                            {labelSaldo ? <p className='-mt-3 ms-40 mb-1 text-gray-500' style={{ fontSize: '12px' }}>Cantidad máxima de aumento de salida posible: {labelSaldo} (No pasar de {limiteSalida})</p> : null}

                            {/* FECHA DE SALIDA */}

                            <div className='flex mb-3'>

                                <label htmlFor="date_picker" className={`w-56 flex items-center font-normal text-[#454545]`} style={{ width: "158px" }}>Fecha de Salida:</label>
                                <input
                                    value={fechaSalida || ""}
                                    onChange={(e) => { setFechaSalida(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value)) }}

                                    type="datetime-local"
                                    max={now_insumo}
                                    className={`px-2 py-1 bg-[#F9F9F9] border border-[#D9D9D9] 
    rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
    ${errors.fecha_salida ? 'border-red-500' : 'border-slate-300'}`}
                                />

                            </div>

                            {errors.fecha_salida && Array.isArray(errors.fecha_salida) && renderErrorMessages(errors.fecha_salida)}

                            <h4 className='text-sm mb-3 text-gray-600'>Datos del insumo seleccionado: </h4>

                            <div className='flex justify-between flex-wrap'>

                                <InputForm
                                    id="ingreso"
                                    labelName="Ingreso:"
                                    inputType="number"
                                    value={labelIngreso}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                                <InputForm
                                    id="salida"
                                    labelName="Salida:"
                                    inputType="number"
                                    value={labelSalida}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                                <InputForm
                                    id="saldo"
                                    labelName="Saldo:"
                                    inputType="number"
                                    value={labelSaldo}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                            </div>
                        </>

                        : <div className='flex justify-center items-center' style={{ height: '217px' }}>
                            <TailSpin className='' width={150} height={150}></TailSpin>
                        </div>}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingButton}
                            className={`flex gap-2 items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-52
                            text-white bg-green-600 me-3
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={onSubmit}
                            type="submit">

                            {loadingButton ?
                                <div className='flex items-center justify-center w-52'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <MdEditDocument /> Editar Salida Insumo
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
