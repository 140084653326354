import React, { useEffect } from 'react'
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default function BarProductosDefec({ id, titulo, labels, data, fechaI, fechaF }) {

    useEffect(() => {

        const generateRandomColor = () => {
            const r = Math.floor(Math.random() * 180);
            const g = Math.floor(Math.random() * 180);
            const b = Math.floor(Math.random() * 180);
            return `rgb(${r}, ${g}, ${b})`;
        };

        // Generar colores aleatorios para cada segmento del gráfico
        const backgroundColors = data.map(() => generateRandomColor());

        const dataHorizontalBar = {
            //labels: ["Polo", "Pantalón", "Camisa"],
            labels: labels,
            datasets: [
                {
                    label: "Producto(s) Defectuoso(s)",
                    //data: [300, 50, 100],
                    data: data,
                    backgroundColor: backgroundColors,
                    borderColor: backgroundColors,
                    borderWidth: 1
                },
            ],
        };

        const configHBar = {
            type: "bar",
            data: dataHorizontalBar,
            options: {
                //indexAxis: 'y',
                responsive: true,
                scales: {
                    x: {
                        ticks: {
                            beginAtZero: true
                        }
                    },
                    y: {
                        beginAtZero: true
                    },                    
                },
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true
                }],
                
                plugins: {
                    legend: {
                        display: false
                    },
                    datalabels: {
                        color: '#fff',
                        font: {
                            size: 14
                        }
                    },
                    title: {
                        display: true,
                        text: `Se muestran los productos defectuosos desde el ${fechaI} hasta el ${fechaF} `
                    }
                }
            },
            plugins: [ChartDataLabels],
        };

        var chartBar = new Chart(document.getElementById(id), configHBar);

        chartBar.canvas.parentNode.style.height = 'auto';
        chartBar.canvas.parentNode.style.width = '1000px';

        return () => {
            chartBar.destroy()
        }

    }, [id, labels, data, titulo]);

    return (
        <div className='me-5 mt-5 w-full flex justify-center'>
            <div className="shadow-lg rounded-lg overflow-hidden max-[1319px]:mt-2 max-[1319px]:mb-8 bg-white">
                <div className="py-3 px-5 bg-white text-sm font-semibold border-b-2">{titulo}</div>
                <canvas className="p-1 mx-12" id={id}></canvas>
            </div>
        </div>
    )
}
