import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import Swal from 'sweetalert2';

import { RotatingLines } from 'react-loader-spinner';

import { FaFilePdf } from 'react-icons/fa';


export default function ClientesPdf({ clientsData }) {

    const [loadingExportPdf, setLoadingExportPdf] = useState(false);

    const dateFormatter = (dateString) => {
        const parsedDate = new Date(dateString).toLocaleString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        return parsedDate;
    };

    const customDateFormatter = (date) => {
        const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'p. m.' : 'a. m.';
        return `${day} de ${month} del ${year} a las ${hours}:${minutes}:${seconds} ${ampm}`;
        //return `${day} de ${month} del ${year}, ${hours}:${minutes}:${seconds}`;
    };

    const handleGeneratePdf = async () => {
        try {
            setLoadingExportPdf(true);

            const data2 = clientsData;
            const currentDateTime = customDateFormatter(new Date());

            // Luego de obtener los datos actualizados, genera el PDF
            const doc = new jsPDF('landscape', 'mm', 'A4', 0);
            let imgData = '/img/logo/logo_empresa_jossmary.png';

            doc.addImage(imgData, 'PNG', 5, 1, 17, 27);
            const title1 = "Empresa Textil Jossmary";
            const title2 = "Reporte de Clientes";
            const subtitle = `Generado el: ${currentDateTime}`;
            const padding = 10;

            const addHeadersAndFooters = (data) => {
                // Add logo
                doc.addImage(imgData, 'PNG', 5, 1, 17, 27);

                // Add title1
                doc.setTextColor('#339A5B').setFont(undefined, 'bold').setFontSize(12);
                let title1Width = doc.getTextWidth(title1);
                let title1Center = (doc.internal.pageSize.width / 2) - (title1Width / 2);
                doc.text(title1, title1Center, padding);

                // Add title2
                let title2Width = doc.getTextWidth(title2);
                let title2Center = (doc.internal.pageSize.width / 2) - (title2Width / 2);
                doc.text(title2, title2Center, padding + 6);

                // Add subtitule
                doc.setTextColor('#19763d').setFont(undefined, 'normal').setFontSize(9);
                doc.text(subtitle, doc.internal.pageSize.width - doc.getTextWidth(subtitle) - 10, padding - 6);


            };

            doc.autoTable({
                head: [['#', 'Nombres', 'A. Paterno', 'A. Materno', 'T. Cliente', 'Tip. Doc.', 'N° Doc.', 'Distrito', 'Dirección', 'Celular']],
                body: data2.map((val, i) => [i + 1,
                val.nombres,
                val.apellido_paterno,
                val.apellido_materno,
                val.tipo_cliente.nombre_tipo,
                val.tipo_documento.nombre_documento,
                val.nro_documento,
                val.distrito.nombre_distrito,
                val.direccion,
                val.celular,
                dateFormatter(val.fecha_registro),
                dateFormatter(val.fecha_editado),
                    /* val.is_active === 1 ? 'Activo' : 'Inactivo' */
                ]),

                headStyles: {
                    fillColor: "#339A5B",
                    textColor: "white"
                },
                margin: { top: 29, right: 5, bottom: 10, left: 5 },
                styles: {
                    fontSize: 8
                },
                didDrawPage: function (data) {
                    addHeadersAndFooters(data);
                }
            });

            setTimeout(() => {
                doc.save(`pdf_clientes_${new Date().toLocaleDateString()}`);
            }, 1000);
        } catch (error) {
            /* console.error("Error generando PDF:", error);
            console.log(error); */
            showSwalMessage("Error", "Ha ocurrido un error al exportar el archivo PDF. Vuelva a intentarlo en unos minutos.", "error");
        } finally {
            setTimeout(() => {
                setLoadingExportPdf(false);
            }, 1000);
        }
    }

    const showSwalMessage = (titulo, texto, icono) => {
        Swal.fire({
            title: titulo,
            text: texto,
            icon: icono,
            confirmButtonColor: "#339A5B",
        });
    }


    return (
        <div className='flex justify-start flex-col mb-4 flex-wrap relative max-w-96'>

            <div className='flex'>

                <button
                    disabled={loadingExportPdf}
                    className={`max-[495px]:ms-1 max-[467px]:ms-0 max-[315px]:mt-3 px-2 py-1 flex items-center
                        ${loadingExportPdf ? 'cursor-not-allowed opacity-50 bg-red-600 text-white border border-red-600 rounded' :
                            `bg-white rounded text-red-600 
                            border border-red-600
                            hover:text-white hover:bg-red-600
                            `}`}
                    style={{ width: '145px' }}
                    onClick={handleGeneratePdf}
                >
                    {loadingExportPdf ?
                        <div className='flex items-center justify-center'>
                            <RotatingLines
                                strokeColor="white"
                                strokeWidth='3'
                                width='22'
                                radius="5"
                                ariaLabel="lines-loading"
                            />
                            <p className='ms-3'>Exportando...</p>
                        </div>
                        : <>
                            <FaFilePdf className='me-3' /> Exportar PDF
                        </>
                    }
                </button>


            </div>
        </div>
    )
}
