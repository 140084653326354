import React, { useState, useEffect } from 'react'
import Config from '../../Config';

import { RotatingLines } from 'react-loader-spinner';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

export default function CambiarContra() {

    const location = useLocation();
    const navigate = useNavigate();

    const [codigo, setCodigo] = useState('');
    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');
    const [confirmarPassword, setConfirmarPassword] = useState('');

    const [errors, setErrors] = useState({});
    const [messageError, setMessageError] = useState('');

    const handlePassword = (e) => {
        setPassword(e.target.value);
        console.log(e.target.value);
    }

    const handleConfirmarPassword = (e) => {
        setConfirmarPassword(e.target.value);
        console.log(e.target.value);
    }

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!sessionStorage.getItem('correo_recuperar')) {
            navigate('/recuperar-password');
        }
        setEmail(sessionStorage.getItem('correo_recuperar'));
    }, [email, navigate]);

    const handleSubmitCambiarContra = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});
        setMessageError('');

        try{
            const response = await Config.postCambiaContrasenia({ email:email, password: password, confirmar_password:confirmarPassword });

            if(response.data.errors){
                setErrors(response.data.errors);
            }

            if(response.data.message){
                setMessageError(response.data.message);                
            }

            if(response.data.cambiado == "true"){
                sessionStorage.removeItem('correo_recuperar');
                notifySuccess('La contraseña se ha cambiado con éxito.');
                navigate("/");
            }

        }catch (error) {
            notify("Ha ocurrido un error. Vuelva a intentarlo en unos minutos.");
        } finally {
            setLoading(false);
        }
    }

    const notifySuccess = (mensaje) => toast.success(mensaje, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const notify = (mensaje) => toast.error(mensaje, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    return (
        <div>
            <div className='h-screen bg-gray-100 flex items-center justify-center'>
                <div className='bg-white w-96 p-6 rounded-md shadow-lg'>

                    <div className='text-center text-xl mb-4 text-gray-500 font-semibold'><h1>Cambiar Contraseña</h1></div>

                    <p className='text-gray-500 my-2 text-sm'>Ingrese el código recibido en su correo</p>

                    {errors.email && <p className="text-red-500 text-sm">{errors.email[0]}</p>}
                    {messageError && <p className="text-red-500 text-sm my-3">{messageError}</p>}

                    <form>

                        <label className="text-gray-500 text-sm" htmlFor='password'>Contraseña</label>
                        <input id="password"
                            className={`w-full py-2 bg-gray-50 text-gray-500 px-2 outline-none mb-1 rounded-md text-sm
                                    placeholder-slate-400
                                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                    border ${errors.password ? 'border-red-500' : 'border-slate-300'}`}
                            type="password"
                            value={password}
                            onChange={handlePassword}
                        />
                        {errors.password && <p className="text-red-500 text-sm -mt-1 mb-2">{errors.password[0]}</p>}

                        <label className="text-gray-500 text-sm" htmlFor='confirmPassword'>Confirmar Contraseña</label>
                        <input id="confirmPassword"
                            className={`w-full py-2 bg-gray-50 text-gray-500 px-2 outline-none mb-1 rounded-md text-sm
                                    placeholder-slate-400
                                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                    border ${errors.confirmar_password ? 'border-red-500' : 'border-slate-300'}`}
                            type="password"
                            value={confirmarPassword}
                            onChange={handleConfirmarPassword}
                        />
                        {errors.confirmar_password && <p className="text-red-500 text-sm -mt-1">{errors.confirmar_password[0]}</p>}

                        <button type="submit"
                            disabled={loading} // Desactiva el botón mientras se carga
                            className={`bg-green-600 w-full mt-3
                                text-white py-2 rounded-md hover:bg-green-700
                                transition-colors font-semibold leading-6
                                focus:ring-4 focus:outline-none focus:ring-green-600 mx-auto block ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
                                onClick={handleSubmitCambiarContra}
                        >
                            {loading ?
                                <div className='flex items-center justify-center'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                :
                                'Validar código'}
                        </button>



                        <hr className='mt-5 h-0.5 border-none bg-gray-200'></hr>

                        <div className='text-center mt-2'>
                            <p className='text-gray-400 text-sm'>¿Desea Ingresar al Sistema? <Link to='/' className='text-blue-400 underline cursor-pointer'>Iniciar Sesión</Link> </p>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}
