import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import AuthUser from '../../pages/pagesAuth/AuthUser';
import Config from '../../Config';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';
import { IoCheckmarkSharp } from "react-icons/io5";


import { FaCopy } from "react-icons/fa6";

import { FaPlus, FaFileCsv } from 'react-icons/fa';
import CsvDownloader from 'react-csv-downloader';
import UserPdf from '../createPdf/UserPdf';

import ModalAnadirCliente from '../forms/Cliente/ModalAnadirCliente';
import ModalEditarCliente from '../forms/Cliente/ModalEditarCliente';

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import ClientesPdf from '../createPdf/Clientes/ClientesPdf';
import ClientesExcel from '../createExcel/ClientesExcel/ClientesExcel';

export default function ClientesTable() {

    const { getRol } = AuthUser();

    const [clientes, setClientes] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [rowCount, setRowCount] = useState(0);

    //Modal
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [editId, setEditId] = useState('');

    //Botones
    const [btnCopiar, setBtnCopiar] = useState(false);

    //Para actualizar luego de agregar o editar
    const updateClienteList = async () => {
        await getClientesAll();
    };

    useEffect(() => {
        getClientesAll();
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        columnFilters,
        globalFilter,
        sorting
    ]);

    const getClientesAll = async () => {
        setTableLoading(true);

        let getClientesList;

        if (getRol() === "Administrador") {
            getClientesList = Config.getClientesListAdmin(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        } else if (getRol() === "Gerente") {
            getClientesList = Config.getClientesListGerente(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                columnFilters,
                sorting
            );
        }

        try {
            const response = await getClientesList;
            console.log(response);
            setClientes(response.data.data);
            setRowCount(response.data.total);
            //setClientes(response.data);
            /* console.log(response); */
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setTableLoading(false);
        }
    }

    const deleteOneCliente = async (id) => {
        setTableLoading(true);
        let deleteOneCliente;

        if (getRol() == "Administrador") {
            deleteOneCliente = Config.deleteClienteAdmin(id);
        }

        try {
            const response = await deleteOneCliente;
            console.log(response);
            await getClientesAll();
            return { status: response.status, message: response.data.message };
        } catch (error) {
            await getClientesAll();
            console.error("Error:", error);
            if (error.response) {
                return { status: error.response.status, message: error.response.data.message };
            } else {
                return { status: 500, message: `Error desconocido. Vuelva a intentarlo.` };
            }
        } finally {
            setTableLoading(false);
        }
    }

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    //SweetAlert2
    const showSwal = async (id) => {
        Swal.fire({
            title: "¿Estás seguro de eliminar este cliente?",
            text: "¡No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc2626",
            cancelButtonColor: "#71717a",
            confirmButtonText: "Sí, eliminar"
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    let mensaje = await deleteOneCliente(id);

                    let title = "";
                    let icon = "";
                    let text = mensaje.message;

                    console.log(mensaje);

                    if (mensaje.status === 200) {
                        title = "¡Eliminado!";
                        icon = "success";
                    } else {
                        title = "Error";
                        icon = "error";
                    }

                    notify(text, icon);
                } catch (error) {
                    console.error("Error eliminando el cliente:", error);
                    setTableLoading(false);
                    notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde.`, "error");
                }
            }
        });
    }

    // const copyTable = () => {
    //     try {
    //         const elTable = document.querySelector('.MuiTableContainer-root');

    //         // Crear una copia de la tabla
    //         const clonedTable = elTable.cloneNode(true);

    //         // Omitir columnas específicas en el header
    //         const headerCells = clonedTable.querySelectorAll('.MuiTableRow-head .MuiTableCell-root');
    //         headerCells.forEach(cell => {
    //             //console.log(headerCells);
    //             if (cell.getAttribute('data-index') === '0' || cell.getAttribute('data-index') === '9') {
    //                 cell.remove();
    //             }
    //         });

    //         //Omitir acciones en encabezados
    //         const headerActions = clonedTable.querySelectorAll('.MuiTableRow-head .MuiTableCell-root .Mui-TableHeadCell-Content .Mui-TableHeadCell-Content-Labels .MuiBadge-root');
    //         headerActions.forEach(cell => {
    //             //console.log(headerActions);
    //             cell.remove();
    //         });



    //         //Omitir filas vacías
    //         const cuerpoTabla = clonedTable.querySelector('.MuiTableBody-root');
    //         const filasVacias = cuerpoTabla.querySelectorAll('tr');
    //         filasVacias.forEach(fila => {
    //             // Obtener el valor de data-index y convertirlo a entero
    //             const dataIndex = parseInt(fila.getAttribute('data-index'));
    //             if (dataIndex % 2 != 0) {
    //                 fila.remove();
    //                 //console.log("Luego de eliminar: ", fila);
    //             }
    //         });

    //         // Omitir datos específicos en el body
    //         const bodyRows = clonedTable.querySelectorAll('.MuiTableRow-root');
    //         bodyRows.forEach(row => {
    //             const cells = row.querySelectorAll('.MuiTableCell-root');
    //             cells.forEach(cell => {
    //                 if (cell.getAttribute('data-index') === '0' || cell.getAttribute('data-index') === '9') {
    //                     cell.remove();
    //                 }
    //             });
    //         });

    //         // Crear un elemento div para contener el título y la tabla
    //         const container = document.createElement('div');

    //         // Añadir título antes de la tabla
    //         const title = document.createElement('div');
    //         title.textContent = 'Tabla de Cliente';
    //         container.appendChild(title);

    //         // Añadir salto de línea antes de la tabla
    //         const lineBreak = document.createElement('br');
    //         container.appendChild(lineBreak);

    //         // Añadir la tabla clonada al contenedor
    //         container.appendChild(clonedTable);

    //         // Agregar el contenedor al documento temporalmente
    //         const tempContainer = document.createElement('div');
    //         tempContainer.appendChild(container);
    //         document.body.appendChild(tempContainer);

    //         // Seleccionar y copiar el contenido de la tabla
    //         let range, sel;
    //         if (document.createRange && window.getSelection) {
    //             range = document.createRange();
    //             sel = window.getSelection();

    //             sel.removeAllRanges();
    //             try {
    //                 range.selectNodeContents(container);
    //                 sel.addRange(range);
    //             } catch (e) {
    //                 range.selectNode(container);
    //                 sel.addRange(range);
    //             }

    //             document.execCommand('copy');
    //         }

    //         // Limpiar y eliminar el contenedor del documento
    //         document.body.removeChild(tempContainer);

    //         //console.log('Element Copied! Paste it in a file');
    //     } catch (error) {
    //         console.log(error);
    //     } finally {
    //         setTimeout(() => {
    //             setBtnCopiar(false);
    //         }, 1000);
    //         setBtnCopiar(true);
    //     }
    // };

    // COLUMNAS DE LA TABLA

    const columns = useMemo(
        () => [
            /* {
                id: "#",
                header: "Nro.",
                size: 1,
                Cell: ({ row }) => {
                    return row.index + 1;
                }
            }, */
            {
                accessorFn: (row) => row.numero_orden_clientes,
                id: "numero_orden_clientes",
                header: "Nro.",
                size: 5,
                enableSorting: false,
            },
            {
                accessorFn: (row) => row.nombres,
                id: "nombres",
                header: "Nombres",
                size: 5
            },
            {
                accessorFn: (row) => row.apellido_paterno,
                id: "apellido_paterno",
                header: "A. Paterno",
                size: 1,
            },
            {
                accessorFn: (row) => row.apellido_materno,
                id: "apellido_materno",
                header: "A. Materno",
                size: 1,
            },
            {
                accessorFn: (row) => row.tipo_cliente.nombre_tipo,
                id: "nombre_tipo",
                header: "T. Cliente",
                size: 1,
            }
            ,
            {
                accessorFn: (row) => row.tipo_documento.nombre_documento,
                id: "nombre_documento",
                header: "Tip. Doc.",
                size: 2,
            },
            {
                accessorFn: (row) => row.nro_documento,
                id: "nro_documento",
                header: "N° de Doc.",
                size: 2
            },
            {
                accessorFn: (row) => row.distrito.nombre_distrito,
                id: "nombre_distrito",
                header: "Distrito",
                size: 2,
            },
            {
                accessorFn: (row) => row.direccion,
                id: "direccion",
                header: "Dirección",
                size: 2,
            },
            {
                accessorFn: (row) => row.celular,
                id: "celular",
                header: "Teléfono",
                size: 2
            },
            {
                accessorFn: (row) => new Date(row.fecha_registro),
                id: "fecha_registro",
                header: "Fecha de Creación",
                size: 4,
                filterVariant: 'datetime-range',
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                },
            },
            {
                accessorFn: (row) => new Date(row.fecha_editado),
                id: "fecha_editado",
                header: "Fecha de Actualización",
                filterVariant: 'datetime-range',
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                },
            },
        ],
        []
    );

    const data = (clientes ? clientes : []);

    //Mapear los datos de clientes para enviarlo a archivo "CSVs"
    /* const clientesParaCSV = clientes.map((cliente, index) => ({
        "#": index+1,
        nombres: cliente.nombres,
        apellido_paterno: cliente.apellido_paterno || '',
        apellido_materno: cliente.apellido_materno || '',
        nombre_tipo: cliente.tipo_cliente.nombre_tipo || '',
        nombre_documento: cliente.tipo_documento.nombre_documento || '',
        nro_documento: cliente.nro_documento || '',
        celular: cliente.celular || '',
        direccion: cliente.direccion || '',
        nombre_distrito: cliente.distrito?.nombre_distrito || '',
        fecha_registro: cliente.fecha_registro || '',
        fecha_editado: cliente.fecha_editado || ''
    })); */

    const formatoFecha = () => {
        try{
            const now = new Date();
            const anio = now.getFullYear();
            const mes = (now.getMonth() + 1 ).toString().padStart(2, '0');
            const dia = now.getDate().toString().padStart(2, '0');
            return `${anio}${mes}${dia}`;
        }catch(error){
            console.log(error);
            return "";
        }
    }

    const mostrarAccionesRol = () => {
        const rol = getRol();
        return rol === "Administrador";
    };

    const table = useMaterialReactTable({
        columns,
        data,

        enableStickyHeader: true,
        enableStickyFooter: true,
        muiTableContainerProps: { sx: { maxHeight: '600px' } },

        enableExpandAll: false, //disable expand all button
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor:
                    theme.palette.mode === 'dark'
                        ? 'rgba(255,210,244,0.1)'
                        : 'rgba(0,0,0,0.1)',
            }),
        }),

        //custom expand button rotation
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
            },
        }),

        renderDetailPanel: ({ row }) =>
            row.original.direccion ? (
                <Box
                    sx={{
                        display: '',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography><span className='font-bold'>Dirección:</span> {row.original.direccion}</Typography>
                    <Typography><span className='font-bold'>Fecha de registro:</span> {row.original.fecha_registro}</Typography>
                    <Typography><span className='font-bold'>Fecha de edición:</span> {row.original.fecha_editado}</Typography>
                    <Typography><span className='font-bold'>Celular:</span> {row.original.celular}</Typography>
                </Box>
            ) : null,

        initialState: {
            showColumnFilters: false,
            density: 'compact',
            columnVisibility: {
                direccion: false,
                fecha_registro: false,
                fecha_editado: false,
                celular: false
            },
            pagination: {
                pageIndex: 0,
                pageSize: 20,
            },
        },

        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,

        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount,

        state: {
            columnFilters,
            globalFilter,
            isLoading: tableLoading,
            pagination,
            sorting,
        },

        localization: MRT_Localization_ES,
        muiSearchTextFieldProps: {
            placeholder: 'Buscar todos los clientes',
            sx: { minWidth: '300px' },
            variant: 'outlined',
        },

        enableRowActions: mostrarAccionesRol(),
        positionActionsColumn: mostrarAccionesRol ? 'last' : undefined,

        renderRowActions: ({ row }) => (
            <Box>
                {getRol() === "Administrador" ?
                    <>
                        <IconButton title='Editar' onClick={() => {
                            setEditId(row.original.id);
                            setOpenEdit(true);
                            console.log("fila", row.original.id);
                        }} color="primary">
                            <EditIcon />
                        </IconButton>
                        <IconButton title='Eliminar' onClick={() => {
                            showSwal(row.original.id);
                            console.log("fila id: ", row.original.id);
                        }}
                            color="error">
                            <DeleteIcon />
                        </IconButton>
                    </>
                    : null}
            </Box>
        ),

        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                {/* <div className=''>
                    <button className={`flex items-center px-2 py-1 border border-sky-600 rounded
                        bg-white text-sky-600 hover:bg-sky-600 hover:text-white
                        `}
                        style={{ width: '145px' }}
                        onClick={copyTable}
                    >
                        {!btnCopiar ?
                            <><FaCopy className='me-3' /> Copiar Tabla</>
                            : <><IoCheckmarkSharp className='me-3' />Copiado</>}
                    </button>
                </div>
                <div>
                    <button className={`flex items-center px-2 py-1 border border-emerald-600 rounded
                        bg-white text-emerald-600 hover:bg-emerald-600 hover:text-white`}
                        style={{ width: '145px' }}
                    >
                        <CsvDownloader
                            columns={columns}
                            datas={clientesParaCSV}
                            filename={`csv_clientes_${formatoFecha()}`}
                            extension='.csv'
                            meta="true"
                        >
                            <div className='flex items-center'><FaFileCsv className='me-3' />Exportar CSV</div>
                        </CsvDownloader>
                    </button>
                </div> */}
                {getRol() === "Administrador" || getRol() === "Gerente" ?
                    <>
                        <ClientesExcel clientes={clientes} />
                        <ClientesPdf
                            clientsData={clientes}
                        />
                    </>
                    : null}
            </Box>
        ),
    });



    return (
        <div>

            {getRol() === "Administrador" ?
                <div className='flex justify-start mb-5 flex-wrap'>
                    <button className='bg-[#339A5B] hover:bg-[#277545] rounded shadow-md text-white p-3 flex items-center'
                        onClick={() => { setOpenAdd(true); console.log(openAdd); }}
                    >
                        <FaPlus className='me-3' />
                        Añadir Cliente
                    </button>
                </div>
                : null}

            <MaterialReactTable
                table={table}
            />

            {getRol() === "Administrador" ?
                <>
                    <ModalAnadirCliente
                        open={openAdd}
                        onClose={() => setOpenAdd(false)}
                        updateClienteList={updateClienteList}
                    />

                    <ModalEditarCliente
                        open={openEdit}
                        editId={editId}
                        onClose={() => setOpenEdit(false)}
                        updateClienteList={updateClienteList}
                    />
                </>

                : null}

        </div>
    )
}
