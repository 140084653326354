import React from 'react'
import BarraNavegacion from '../../components/navbarCliente/BarraNavegacion';
import FooterNav from '../../components/navbarCliente/FooterNav';
import { Link } from 'react-router-dom';
import { FaCheck } from "react-icons/fa6";

import { FaHome } from "react-icons/fa";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";


export default function MapaSitio() {
    return (
        <div className='min-h-screen bg-gray-100 flex flex-col'>
            
            <BarraNavegacion/>
            
            <div className="flex justify-center items-center flex-col text-white bg-green-700 w-full h-24">
              <h1 style={{ fontSize: '24px' }}>Mapa del Sitio</h1>
            </div>

            <div className='px-52 my-5 text-sm'>

                <div className='flex items-center' style={{ fontSize: '14px' }}>
                    <Link to="/"><FaHome className='hover:text-green-700' /></Link>                    
                    <MdOutlineKeyboardArrowRight className='mx-3' style={{ fontSize: '14px' }}/>
                    <p className='font-semibold' style={{ fontSize: '12px' }}>MAPA DE SITIO</p>
                </div>

                <hr className='my-4'/>

                <Link to="/" className="text-green-700 hover:underline font-semibold mb-8">INICIO</Link><br></br>

                <Link to="/productos-joss" className="text-green-700 hover:underline font-semibold">Productos</Link>
                <ul className="ml-5 pl-5 mb-4">
                    <li className='flex items-center'><FaCheck className='me-2 text-green-900' style={{ fontSize: '12px' }}/> Pantalones</li>
                    <li className='flex items-center'><FaCheck className='me-2 text-green-900' style={{ fontSize: '12px' }}/> Polos</li>
                    <li className='flex items-center'><FaCheck className='me-2 text-green-900' style={{ fontSize: '12px' }}/> Sacos</li>
                </ul>
                <Link to="/servicios-joss" className="text-green-700 hover:underline font-semibold">Servicios</Link>
                <ul className="ml-5 pl-5 mb-4">
                    <li className='flex items-center'><FaCheck className='me-2 text-green-900' style={{ fontSize: '12px' }}/> Planchado</li>
                    <li className='flex items-center'><FaCheck className='me-2 text-green-900' style={{ fontSize: '12px' }}/> Lavado</li>
                    <li className='flex items-center'><FaCheck className='me-2 text-green-900' style={{ fontSize: '12px' }}/> Estampado</li>
                </ul>

                <Link to="/quienes-somos" className="text-green-700 hover:underline font-semibold">Conoce Jossmary</Link>

            </div>
                
            <FooterNav/>
            
        </div>
      );
}
