import React, { useState } from 'react';
import * as XLSX from "xlsx";
import * as XLSXStyle from "xlsx-js-style";

import Swal from 'sweetalert2';

import { RotatingLines } from 'react-loader-spinner';
import { FaFileCsv } from 'react-icons/fa';

export default function ClientesExcel({ clientes }) {

    const [loadingDataExcel, setLoadingDataExcel] = useState(false);

    const getFormattedDate = () => {
        const date = new Date();
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        };
        return date.toLocaleString('es-ES', options);
    };

    const getFormattedHour = () => {
        const date = new Date();
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };
        return date.toLocaleString('es-ES', options);
    };

    const titulo = [{ A: "Reporte de Clientes"}, {}];

    const InfoAdicional = {
        A: "Generado por Jossmary Web"
    };

    const FechaActual = {
        A: `Reporte generado el ${getFormattedDate()} a las ${getFormattedHour()}`
    };

    const longitudes = [5, 15, 20, 20, 15, 20, 20, 20, 20, 20, 25, 25];

    const handleExcelDownload = () => {
        setLoadingDataExcel(true);

        let table = [
            {
                A: "Nro.",
                B: "Nombres",
                C: "Apellido Paterno",
                D: "Apellido Materno",
                E: "Tipo de Cliente",
                F: "Tipo de Documento",
                G: "N° de Documento",
                H: "Distrito",
                I: "Dirección",
                J: "Celular",
                K: "Fecha de Registro",
                L: "Fecha de Edición",
            },
            ...clientes.map((cliente, index) => ({
                A: index+1,
                B: cliente.nombres,
                C: cliente.apellido_paterno,
                D: cliente.apellido_materno,
                E: cliente.tipo_cliente.nombre_tipo,
                F: cliente.tipo_documento.nombre_documento,
                G: cliente.nro_documento,
                H: cliente.distrito.nombre_distrito,
                I: cliente.direccion,
                J: cliente.celular,
                K: dateFormatter(cliente.fecha_registro),
                L: dateFormatter(cliente.fecha_editado),
            }))
        ];

        const dataFinal = [...titulo, FechaActual, {}, ...table, {}, InfoAdicional];

        setTimeout(() => {
            creandoArchivo(dataFinal);
            setLoadingDataExcel(false);
        }, 1000);
    }

    const creandoArchivo = (dataFinal) => {
        try{
            const libro = XLSX.utils.book_new();
    
            const hoja = XLSX.utils.json_to_sheet(dataFinal, {skipHeader: true});
    
            //Unir celdas
            hoja["!merges"] = [
                XLSX.utils.decode_range("A1:L1"),
                XLSX.utils.decode_range("A3:L3"),
            ];
    
            // Estilo para A1:G1
            const tituloStyle = {
                font: { bold: true, color: { rgb: "33691E" }, sz: 18 },
                //fill: { fgColor: { rgb: "C5E1A5" } },
                alignment: { horizontal: "center", vertical: "center" }
            };
    
            // Aplicar estilo a A1:G1
            const tituloCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
            tituloCols.forEach(col => {
                const cell = `${col}1`;
                if (hoja[cell]) {
                    hoja[cell].s = tituloStyle;
                }
            });
    
            //Aplicar estilos a A3
            const reporteStyle = {
                font: { bold: true, color: { rgb: "33691E" }, sz: 10 },
            };
    
            //Aplicar estilos a A3
            const reporteCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
            reporteCols.forEach(col => {
                const cell = `${col}3`;
                if (hoja[cell]) {
                    hoja[cell].s = reporteStyle;
                }
            });
    
            // Agregar estilo a los encabezados A5:G5
            const headerStyle = {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "4CAF50" } },
                alignment: { horizontal: "center", vertical: "center" }
            };
    
            // Aplicar estilo a los encabezados
            const cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
            cols.forEach(col => {
                const cell = `${col}5`;
                if (hoja[cell]) {
                    hoja[cell].s = headerStyle;
                }
            });
    
            let propiedades = [];
    
            longitudes.forEach((col) => {
                propiedades.push({
                    width: col,
                });
            });
    
            hoja["!cols"] = propiedades;
    
            XLSXStyle.utils.book_append_sheet(libro, hoja, "Usuarios");
    
            XLSXStyle.writeFile(libro, `excel_usuarios_${new Date().toLocaleDateString()}.xlsx`);
        }catch(error){
            showSwalMessage("Error", "Ha ocurrido un error al exportar el archivo Excel. Vuelva a intentarlo en unos minutos.", "error");
        }
    };

    const dateFormatter = (dateString) => {
        const parsedDate = new Date(dateString).toLocaleString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        return parsedDate;
    };

    const showSwalMessage = (titulo, texto, icono) => {
        Swal.fire({
            title: titulo,
            text: texto,
            icon: icono,
            confirmButtonColor: "#339A5B",
        });
    }

  return (
    <div>
        <button disabled={loadingDataExcel}
        className={`max-[495px]:ms-1 max-[467px]:ms-0 max-[315px]:mt-3
            ${loadingDataExcel ? 'bg-lime-600 text-white cursor-not-allowed opacity-50 rounded border border-lime-600' :
            `bg-white rounded text-lime-600 
            border border-lime-600
            hover:text-white hover:bg-lime-600 `}
            px-2 py-1 flex items-center 
            `}
               onClick={handleExcelDownload}
        >
            {loadingDataExcel ?
                        <div className='flex items-center justify-center'>
                            <RotatingLines
                                strokeColor="white"
                                strokeWidth='3'
                                width='22'
                                radius="5"
                                ariaLabel="lines-loading"
                            />
                            <p className='ms-3'>Exportando...</p>
                        </div>
                        : <>
                            <FaFileCsv className='me-3' /> Exportar Excel
                        </>
                    }
        </button>
    </div>
  )
}
