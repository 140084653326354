import React, { useEffect, useState } from 'react';

import Config from '../../../Config';
import AuthUser from '../../../pages/pagesAuth/AuthUser';
import InputForm from '../../inputs/InputForm';
import TextAreaForm from '../../inputs/TextAreaForm';

import Modal from '../../modals/Modal';

import { RotatingLines, TailSpin } from 'react-loader-spinner';
import { MdEditDocument } from "react-icons/md";

import { toast } from 'react-toastify';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

export default function ModalEditarInsumo({ open, onClose, updateInsumoList, editId }) {

    const { getRol } = AuthUser();

    const id = editId;

    //Select import
    /* const [arrayCatInsumos, setArrayCatInsumos] = useState([]);
    const [arrayProveedores, setArrayProveedores] = useState([]); */

    const [categoriaInsumoId, setCategoriaInsumoId] = useState('');
    const [proveedorId, setProveedorId] = useState('');
    const [marcaInsumoId, setMarcaInsumoId] = useState('');
    const [nombre, setNombre] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [color, setColor] = useState('');

    const [errors, setErrors] = useState({});

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            cargarInsumo();
            setZIndex(9999);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            setZIndex(-1);
        }

        console.log("INdex: ",zIndex);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const loadCategoriasOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectCategoriaInsumosAdmin(globalFilter);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectCategoriaInsumosOperario(globalFilter);
            }
            const arrayCatInsumos = response.data;
            if (arrayCatInsumos.exito == 0) {
                return [];
            }

            return arrayCatInsumos.map(categoria_insumo => ({
                value: categoria_insumo.id_catinsumos,
                label: `${categoria_insumo.id_catinsumos} - ${categoria_insumo.nombre_categoria_insumo}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar las categorías de insumos. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const loadProveedoresOptions = async (inputValue) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectProveedoresAdmin(inputValue);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectProveedoresOperario(inputValue);
            }
            const arrayProveedores = response.data;
            if (arrayProveedores.exito == '0') {
                return [];
            }
            return arrayProveedores.map(proveedor => ({
                value: proveedor.id_proveedor,
                label: `${proveedor.nombre_proveedor} - ${proveedor.ruc} - ${proveedor.estado_proveedor == "1" ? 'Activo': (proveedor.estado_proveedor == "2" ? 'Inactivo' : 'Inactivo')}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los proveedores. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const loadMarcasInsumosOptions = async (inputValue) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectMarcasInsumosAdmin(inputValue);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectMarcasInsumosOperario(inputValue);
            }
            const arrayMarcasInsumos = response.data;
            if (arrayMarcasInsumos.exito == '0') {
                return [];
            }

            console.log(arrayMarcasInsumos);
            return arrayMarcasInsumos.map(marca => ({
                value: marca.id_marcainsumo,
                label: `${marca.id_marcainsumo} - ${marca.nombre_marca_insumo}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar las marcas de los insumos. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const cargarInsumo = async () => {
        setLoadingData(true);
        setErrors({});

        try {

            let response;

            if (getRol() === "Administrador") {
                response = await Config.getOneInsumoAdmin(id);
            } else if (getRol() === "Operario") {
                response = await Config.getOneInsumoOperario(id);
            }

            console.log("Cargar insumo: ", response);

            if (response.data.exito == 0) {
                notify(`Error: ${response.data.message}`, "error");
            } else {

                setCategoriaInsumoId(response.data.categoria_insumo);
                setProveedorId(response.data.proveedor);
                setMarcaInsumoId(response.data.marca_insumo);
                setNombre(response.data.nombre);
                setDescripcion(response.data.descripcion);
                setColor(response.data.color);
            }

        } catch (error) {
            console.error(error);
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        } finally {
            setLoadingData(false);
        };
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadingButton(true);

        try {

            const insumoData = {
                categoria_insumo_id: (categoriaInsumoId.id_catinsumos ? categoriaInsumoId.id_catinsumos : categoriaInsumoId.value),
                proveedor_id: (proveedorId.id_proveedor ? proveedorId.id_proveedor : proveedorId.value),
                marca_insumo_id: (marcaInsumoId.id_marcainsumo ? marcaInsumoId.id_marcainsumo : marcaInsumoId.value),
                nombre: nombre,
                descripcion: descripcion,
                color: color,
            };

            console.log(insumoData);

            let response;

            if (getRol() === "Administrador") {
                response = await Config.putUpdateInsumoAdmin(id, insumoData);
            } else if (getRol() === "Operario") {
                response = await Config.putUpdateInsumoOperario(id, insumoData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(`Error: ${response.data.message}`, "error");
                } else if (response.data.exito == 1) {
                    notify("El insumo ha sido editado correctamente.", "success");
                    setCategoriaInsumoId('');
                    setProveedorId('');
                    setMarcaInsumoId('');
                    setNombre('');
                    setDescripcion('');
                    setColor('');
                    onClose();
                    updateInsumoList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }


        } catch (error) {
            console.log(error);
            updateInsumoList();
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
            setLoadingButton(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div key={index} style={{ marginLeft: '156px' }}><p className="text-red-500 text-sm -mt-3 mb-3 ms-1">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),

        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Editar</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Editar Insumo</h3>

                <form>

                    {!loadingData ?

                        <>

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='categoria_id' className='w-56 flex items-center font-normal text-[#454545]'>Categoría de Insumo: </label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadCategoriasOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.categoria_insumo_id)}
                                    id="categoria_id"
                                    //value={categoriaInsumoId}
                                    defaultValue={{ id: categoriaInsumoId.id_catinsumos, label: `${categoriaInsumoId.id_catinsumos} - ${categoriaInsumoId.nombre_categoria_insumo}` }}
                                    onChange={(option, id) => { setCategoriaInsumoId(option); console.log(option, id); }}
                                    placeholder="Seleccionar categoría de insumo..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.categoria_insumo_id && Array.isArray(errors.categoria_insumo_id) && renderErrorMessages(errors.categoria_insumo_id)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='proveedor_id' className='w-56 flex items-center font-normal text-[#454545]'>Proveedor: </label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadProveedoresOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.proveedor_id)}
                                    id="proveedor_id"
                                    //value={proveedorId}
                                    defaultValue={{ id: proveedorId.id_proveedor, label: `${proveedorId.nombre_proveedor} - ${proveedorId.ruc} - ${proveedorId.estado_proveedor == "1" ? 'Activo': ( proveedorId.estado_proveedor == "2" ? 'Inactivo' : 'Inactivo')}` }}
                                    onChange={(option, id) => { setProveedorId(option); console.log(option, id); }}
                                    placeholder="Seleccionar proveedor..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.proveedor_id && Array.isArray(errors.proveedor_id) && renderErrorMessages(errors.proveedor_id)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='marca_insumo_id' className='w-56 flex items-center font-normal text-[#454545]'>Marca: </label>                                
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadMarcasInsumosOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.marca_insumo_id)}
                                    id="marca_insumo_id"
                                    //value={proveedorId}
                                    defaultValue={{ id: marcaInsumoId.id_marcainsumo, label: `${marcaInsumoId.id_marcainsumo} - ${marcaInsumoId.nombre_marca_insumo}` }}
                                    onChange={(option, id) => { setMarcaInsumoId(option); console.log(option, id); }}
                                    placeholder="Seleccionar marca..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.marca_insumo_id && Array.isArray(errors.marca_insumo_id) && renderErrorMessages(errors.marca_insumo_id)}

                            <InputForm
                                id="insumo"
                                labelName="Nombre del Insumo:"
                                inputType="text"
                                value={nombre}
                                funcion={(e) => setNombre(e.target.value)}
                                errors={errors.nombre}
                                labelWidth="w-56"
                            />

                            {errors.nombre && Array.isArray(errors.nombre) && renderErrorMessages(errors.nombre)}

                            <InputForm
                                id="color"
                                labelName="Color:"
                                inputType="text"
                                value={color}
                                funcion={(e) => setColor(e.target.value)}
                                errors={errors.color}
                                labelWidth="w-56"
                            />

                            {errors.color && Array.isArray(errors.color) && renderErrorMessages(errors.color)}

                            <TextAreaForm
                                id="descripcion"
                                labelNameArea="Descripción:"
                                value={descripcion}
                                row={4}
                                cols={40}
                                funcion={(e) => setDescripcion(e.target.value)}
                                errors={errors.descripcion}
                                labelWidth="w-56"
                            />

                            {errors.descripcion && Array.isArray(errors.descripcion) && renderErrorMessages(errors.descripcion)}

                        </>

                        : <div className='flex justify-center items-center' style={{ height: '217px' }}>
                            <TailSpin className='' width={150} height={150}></TailSpin>
                        </div>}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingButton}
                            className={`flex gap-2 items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-52
                            text-white bg-green-600 me-3
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={onSubmit}
                            type="submit">

                            {loadingButton ?
                                <div className='flex items-center justify-center w-52'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <MdEditDocument /> Editar Insumo
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
