import React from 'react'
import BarraNavegacion from '../../components/navbarCliente/BarraNavegacion';
import FooterNav from '../../components/navbarCliente/FooterNav';

export default function ServiciosMuestra() {
  return (
    <div className='min-h-screen bg-gray-100 flex flex-col'>
        
        <BarraNavegacion/>
        
        <div className="flex justify-center items-center flex-col text-white bg-green-700 w-full h-24">
          <h1 style={{ fontSize: '24px' }}>Servicios</h1>
        </div>

        <div className='px-52'>

            <div>
                <h1 className='text-green-700 mb-10' style={{ fontSize: '20px' }}>Servicios que Ofrecemos</h1>
            </div>

            <div className='flex justify-between flex-wrap mb-10'>

                <div className='flex flex-col my-4'>
                    <h5 className='font-semibold' style={{ fontSize: '18px' }}>Lavado</h5>

                    <img className='shadow-xl border rounded' src='/img/servicios/lavado.jpg' alt='pantalones'
                    style={{ width: '390px', height: '300px' }}
                    />

                    <p className='mt-6' style={{ width: '380px' }}>En Textil Jossmary, ofrecemos un servicio de lavado textil de alta calidad diseñado para mantener tus prendas y tejidos en perfectas condiciones. Utilizamos productos y técnicas especializadas que garantizan una limpieza profunda y segura, preservando la textura y los colores de tus textiles. Nuestro equipo profesional se encarga de cada detalle, desde el pretratamiento hasta el secado, para asegurar resultados impecables. Confía en nosotros para mantener la apariencia y durabilidad de tus productos textiles. ¡Déjanos cuidar de tus prendas como si fueran nuestras!</p>
                </div>

                <div className='flex flex-col my-4'>
                    <h5 className='font-semibold' style={{ fontSize: '18px' }}>Planchado</h5>

                    <img className='shadow-xl border rounded' src='/img/servicios/planchado.jpg' alt='polos'
                    style={{ width: '390px', height: '300px' }}
                    />

                    <p className='mt-6' style={{ width: '380px' }}>En Textil Jossmary, nuestro servicio de planchado textil asegura que tus prendas y tejidos luzcan impecables y bien cuidados. Utilizamos equipos de última tecnología y técnicas especializadas para eliminar arrugas y pliegues, dando a tus textiles un acabado profesional y elegante. Nuestro equipo de expertos se encarga de cada prenda con atención al detalle, garantizando que cada pieza reciba el tratamiento adecuado según su tipo de tela. Confía en nosotros para obtener un planchado perfecto que realce la presentación de tus textiles. ¡Deja que transformemos tus prendas en su mejor versión!</p>
                </div>

                <div className='flex flex-col my-4'>
                    <h5 className='font-semibold' style={{ fontSize: '18px' }}>Estampado</h5>

                    <img className='shadow-xl border rounded' src='/img/servicios/estampado.jpg' alt='sacos'
                    style={{ width: '390px', height: '300px' }}
                    />

                    <p className='mt-6' style={{ width: '380px' }}>En Textil Jossmary, ofrecemos un servicio de estampado textil que combina creatividad y precisión para personalizar tus prendas y tejidos. Utilizamos técnicas avanzadas y tintas de alta calidad para garantizar estampados vibrantes y duraderos. Nuestro equipo de diseño trabaja contigo para crear patrones únicos que reflejen tu estilo o identidad de marca. Ya sea para moda, eventos especiales o promociones, nuestro servicio de estampado asegura resultados excepcionales que hacen destacar tus textiles. ¡Haz que tus ideas cobren vida con nuestros estampados personalizados!</p>
                </div>

            </div>

        </div>
        
        <FooterNav/>
        
    </div>
  )
}
