import React, { useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
import DashboardCards from '../../components/dashboardComponents/DashboardCards';
import CircleGraphic from '../../components/dashboardComponents/CircleGraphic';

import { FaFilePdf } from 'react-icons/fa';

import { Link, useNavigate } from 'react-router-dom';
import AuthUser from '../pagesAuth/AuthUser';

import { useMs } from '../../context/MsContext';
import Swal from 'sweetalert2';

import SingleCard from '../../components/dashboardComponents/SingleCard';

import { RotatingLines } from 'react-loader-spinner';

import { MdErrorOutline } from "react-icons/md";
import { FaMoneyBillWave } from "react-icons/fa";
import { BiNotepad } from "react-icons/bi";
import { TiMessages } from "react-icons/ti";
import { TbSquareArrowUp } from "react-icons/tb";

import { FaSackDollar } from "react-icons/fa6";
import { CgTrash } from "react-icons/cg";

import { FaUsers } from "react-icons/fa6";
import { HiUsers } from "react-icons/hi2";

import { MdOutlineCarCrash } from "react-icons/md";
import { FaTruckLoading } from "react-icons/fa";

import { FaUserCheck } from "react-icons/fa";
import { MdPendingActions } from "react-icons/md";

import { CiFilter } from "react-icons/ci";


import Config from '../../Config';
import TopInsumosUsados from '../../components/dashboardComponents/Tables/TopInsumosUsados';
import TopProductosSalidas from '../../components/dashboardComponents/Tables/TopProductosSalidas';
import BarProductosDefec from '../../components/dashboardComponents/BarGraphics/BarProductosDefec';
import CalendarioInsumos from '../../components/dashboardComponents/Calendario/CalendarioInsumos';
import CalendarioProductos from '../../components/dashboardComponents/Calendario/CalendarioProductos';
import InsumosConBajoStock from '../../components/dashboardComponents/Tables/InsumosConBajoStock';
import ProductosConBajoStock from '../../components/dashboardComponents/Tables/ProductosConBajoStock';
import IngresoInsumosSalidasProductos from '../../components/dashboardComponents/LineChartsGraphics/IngresoInsumosSalidasProductos';

export default function Dashboard() {

  const { setToken, getToken, getRol } = AuthUser();

  // Función para formatear la fecha
  const formatFechaForDB = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = "00";
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  //Función para mostrar fechas como:  "1 de junio 2024"
  const customDateStringFormatter = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

    const formattedDate = `${day} de ${months[monthIndex]} del ${year}`;

    return formattedDate;
    //return `${day} de ${month} del ${year}, ${hours}:${minutes}:${seconds}`;
  };

  const now = new Date();
  const nowLocalString = formatFechaForDB(now);

  // Fecha inicial: primer día del mes actual
  const fechaInicialDefault = new Date(now.getFullYear(), now.getMonth(), 1);

  const [fechaInicial, setFechaInicial] = useState(formatFechaForDB(fechaInicialDefault));
  const [fechaFinal, setFechaFinal] = useState(formatFechaForDB(now));
  const [anioLineInvGanDefecto, setAnioLineInvGanDefecto] = useState(new Date().getFullYear());

  //Fechas formateadas solo dia, mes y año
  const [fechaFormatI, setFechaFormatI] = useState(customDateStringFormatter(fechaInicial));
  const [fechaFormatF, setFechaFormatF] = useState(customDateStringFormatter(fechaFinal));

  //Tarjetas dashboard
  const [insumosBajo, setInsumosBajo] = useState('');
  const [insumosIngresoT, setInsumosIngresoT] = useState('');
  const [insumoInvertidoT, setInsumoInvertidoT] = useState('');
  const [insumoSalidaT, setInsumoSalidaT] = useState('');

  const [productosBajo, setProductosBajo] = useState('');
  const [productosGanado, setProductosGanado] = useState('');
  const [productosMerma, setProductosMerma] = useState('');
  const [productosCantIngreso, setProductosCantIngreso] = useState('');

  const [clientesFecha, setClientesFecha] = useState('');
  const [clienteTotales, setClientesTotales] = useState('');

  const [proveedoresActivos, setProveedoresActivos] = useState('');
  const [proveedoresInactivos, setProveedoresInactivos] = useState('');

  const [serviciosCompletados, setServiciosCompletados] = useState('');
  const [serviciosPendientes, setServiciosPendientes] = useState('');

  //Gráficos
  //
  const [productosDefectuosos, setProductosDefectuosos] = useState([]);

  const [iInsumosLineChart, setIInsumosLineChart] = useState([]);
  const [gProductosLineChart, setGProductosLineChart] = useState([]);

  const [uniqueYears, setUniqueYears] = useState([]);
  const [labelAnioFiltro, setLabelAnioFiltro] = useState('');

  //Tablas
  const [topInsumosSalidas, setTopInsumosSalidas] = useState([]);
  const [topProductosSalidas, setTopProductosSalidas] = useState([]);

  const [listaInsumosBajoStock, setListaInsumosBajoStock] = useState([]);
  const [listaProductosBajoStock, setListaProductosBajoStock] = useState([]);




  //Calendario
  const [eventosInsumos, setEventosInsumos] = useState([]);
  const [eventosProductos, setEventosProductos] = useState([]);

  /* ********************************************************* */

  /* ********************************************************* */

  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loadingBtnPdf, setLoadingBtnPdf] = useState(false);

  const [errors, setErrors] = useState({});

  const renderErrorMessages = (messages) => {
    return messages.map((msg, index) => (
      <div key={index} style={{ marginLeft: '162px' }}>
        <p className="text-red-500 text-sm -mt-3 mb-3">{msg}</p>
      </div>
    ));
  };

  const { ms } = useMs();
  const navigate = useNavigate();

  useEffect(() => {
    if (getRol() !== "Administrador" && getRol() !== "Gerente") {
      navigate("/usuario/lista-insumos-principal");
    }
    document.title = "SDI Jossmary | Dashboard";

    getDashboardAll();

    //Poner fechas



  }, []);

  const getDashboardAll = async () => {
    try {

      setLoadingFilter(true);

      let getDashboardList;

      if (getRol() === "Administrador") {
        getDashboardList = Config.getDashboardAdmin(
          fechaInicial,
          fechaFinal,
          anioLineInvGanDefecto
        );
      } else if (getRol() === "Gerente") {
        getDashboardList = Config.getDashboardGerente(
          fechaInicial,
          fechaFinal,
          anioLineInvGanDefecto
        );
      }

      const response = await getDashboardList;

      setFechaFormatI(customDateStringFormatter(fechaInicial));
      setFechaFormatF(customDateStringFormatter(fechaFinal));

      setInsumosBajo(response.data.insumos_bajo_stock);
      setInsumosIngresoT(response.data.insumos_ingreso_total);
      setInsumoInvertidoT(response.data.insumos_inversion_total);
      setInsumoSalidaT(response.data.insumos_salida_total);

      setProductosBajo(response.data.productos_bajo_stock);
      setProductosGanado(response.data.productos_ganado_total);
      setProductosMerma(response.data.productos_ingreso_merma_total);
      setProductosCantIngreso(response.data.productos_ingreso_total);

      setClientesFecha(response.data.clientes_registrados_por_fecha);
      setClientesTotales(response.data.clientes_totales);

      setProveedoresActivos(response.data.proveedores_activos);
      setProveedoresInactivos(response.data.proveedores_inactivos);

      setServiciosCompletados(response.data.servicios_completados);
      setServiciosPendientes(response.data.servicios_pendientes);

      //Gráficos
      setProductosDefectuosos(response.data.suma_productos_defectuosos);

      setUniqueYears(response.data.anios_para_filtros_considerados);
      setLabelAnioFiltro(response.data.filtroAnio);

      setIInsumosLineChart(response.data.invertido_insumos_line1);
      setGProductosLineChart(response.data.ganado_productos_line2);

      //Tablas
      setTopInsumosSalidas(response.data.top_insumos_mas_usados);
      setTopProductosSalidas(response.data.top_productos_mas_salidas);

      setListaInsumosBajoStock(response.data.lista_insumos_bajo_stock);
      setListaProductosBajoStock(response.data.lista_productos_bajo_stock);


      //Calendario
      setEventosInsumos(response.data.ingresos_salidas_insumos_report);
      setEventosProductos(response.data.ingresos_salidas_productos_report);

    } catch (error) {
      console.error("Error:", error);
      showSwalMessage("Error", "Ha ocurrido un error al generar el Dashboard. Vuelva a intentarlo en unos minutos.", "error");
    } finally {
      setLoadingFilter(false);
    }
  }

  const exportToPDF = () => {
    try {
      setLoadingBtnPdf(true);
      const element = document.getElementById('exportSection');

      let fechaFormateadaInicial = customDateStringFormatter(fechaInicial);
      let fechaFormateadaFinal = customDateStringFormatter(fechaFinal);

      const fechaActual = new Date();
      const nombreArchivo = `reporte_dashboard_${fechaActual.getFullYear()}-${fechaActual.getMonth() + 1}-${fechaActual.getDate()}.pdf`;

      const opt = {
        margin: [25, 5, 5, 10],
        filename: nombreArchivo,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, ignoreElements: (element) => element.classList.contains('ignore-pdf') },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
        pagebreak: { before: '.page-break-tables', after: ['#page_break_one', '#after2'] }
      };

      html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);

          // Añadir encabezado
          pdf.setFontSize(16);
          pdf.setTextColor(0, 128, 0);
          pdf.text('Reporte Dashboard - Jossmary', 20, 10);

          pdf.setFontSize(10);
          pdf.setTextColor(0, 128, 0);
          pdf.text(`Página ${i} de ${totalPages}`, pdf.internal.pageSize.getWidth() - 30, pdf.internal.pageSize.getHeight() - 10);

          pdf.setFontSize(10);
          pdf.setTextColor(0, 128, 0);
          pdf.text(`El reporte ha sido generado con datos entre las fechas: ${fechaFormateadaInicial} hasta el ${fechaFormateadaFinal}`, 20, 15);
        }

        pdf.save(nombreArchivo);
      });

    } catch (error) {
      showSwalMessage("Error", "Ha ocurrido un error al exportar el archivo PDF. Vuelva a intentarlo en unos minutos.", "error");
    } finally {
      setLoadingBtnPdf(false);
    }
  };

  const showSwalMessage = (titulo, texto, icono) => {
    Swal.fire({
        title: titulo,
        text: texto,
        icon: icono,
        confirmButtonColor: "#339A5B",
    });
}

  return (
    <div className={`${ms ? 'ms-4 transition-all' : 'ms-56 transition-all'} 
    mt-16 pt-2 pb-16
    overflow-y-auto  
    max-[768px]:${ms ? 'ms-2 transition-all' : 'ms-36 transition-all'}
    max-[480px]:mt-28
    `}>

      <div id="exportSection">

        <h1 className='mb-8 text-2xl text-slate-500 font-semibold'>Dashboard - Resumen</h1>
        <button onClick={exportToPDF} className={`ignore-pdf
        ${loadingBtnPdf ? 'bg-red-600 text-white opacity-50' : 'text-red-600 border border-red-600 hover:bg-red-600 hover:text-white'}  
      rounded py-1 px-2 mb-2 flex items-center`}
        >
          <FaFilePdf className='me-2' />Exportar a PDF
        </button>

        <form className='ignore-pdf'>
          <div className='flex flex-col flex-wrap mb-4 me-3
        border border-gray-300 rounded-sm p-2'>
            <h1 className='text-xl mb-3'>Filtros: </h1>
            <div className='flex'>

              <div className='flex'>

                <label htmlFor="date_picker" className={`flex items-center font-normal text-[#454545] text-sm me-2`}>Fecha Inicial:</label>
                <input
                  value={fechaInicial || ""}
                  onChange={(e) => { setFechaInicial(formatFechaForDB(e.target.value)); }}

                  type="datetime-local"
                  max={fechaFinal}
                  className={`px-4 py-1 bg-[#F9F9F9] border border-[#D9D9D9] text-sm me-4
              rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
              ${errors.fecha_ingreso ? 'border-red-500' : 'border-slate-300'}`}
                />

              </div>

              {errors.fecha_ingreso && Array.isArray(errors.fecha_ingreso) && renderErrorMessages(errors.fecha_ingreso)}
              <div className='flex'>

                <label htmlFor="date_picker" className={`flex items-center font-normal text-[#454545] text-sm me-2`}>Fecha Final:</label>
                <input
                  value={fechaFinal || ""}
                  onChange={(e) => { setFechaFinal(formatFechaForDB(e.target.value)); }}

                  type="datetime-local"
                  min={fechaInicial}
                  max={nowLocalString}
                  className={`px-4 py-1 bg-[#F9F9F9] border border-[#D9D9D9] text-sm
                rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
                ${errors.fecha_ingreso ? 'border-red-500' : 'border-slate-300'}`}
                />

              </div>

              {errors.fecha_ingreso && Array.isArray(errors.fecha_ingreso) && renderErrorMessages(errors.fecha_ingreso)}

              <div className='ms-4'>
                <button
                  disabled={loadingFilter}
                  className={`bg-sky-500 text-white rounded shadow-sm px-4 py-1 flex items-center
                ${loadingFilter ? 'cursor-not-allowed opacity-50' : ''}
                hover:bg-sky-600 transition`}
                  onClick={getDashboardAll}>
                  {loadingFilter ?
                    <div className='flex items-center justify-center'>
                      <RotatingLines
                        strokeColor="white"
                        strokeWidth='3'
                        width='22'
                        radius="5"
                        ariaLabel="lines-loading"
                        className='me-2'
                      />
                      Filtrando
                    </div>
                    : <>
                      <CiFilter className='me-2' /> Filtrar
                    </>
                  }

                </button>
              </div>
            </div>
          </div>



        </form>



        {/* <DashboardCards /> */}
        <div className={`${loadingFilter ? 'opacity-50 cursor-not-allowed' : null}`}>
          {/* <div className='d-flex justify-center items-center z-10'>Hola</div> */}
          <div className='flex flex-wrap justify-evenly mb-16'>

            {/* INSUMOS */}


            <SingleCard titulo_tarjeta="Cantidad de Insumos con Bajo o Nulo Stock"
              tarjeta_logo={<MdErrorOutline size={40} className='text-red-500' />}
              title_card_style="text-red-500 font-medium"
              color='border-red-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${insumosBajo}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Total Invertido en Insumos Durante el Mes"
              tarjeta_logo={<FaMoneyBillWave size={40} className='text-green-500' />}
              title_card_style="text-green-500 font-medium"
              color='border-green-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `S/. ${(Math.round(insumoInvertidoT * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                : <RotatingLines
                  strokeColor="gray"
                  strokeWidth='3'
                  width='22'
                  radius="5"
                  ariaLabel="lines-loading"
                  className='me-2'
                />}
            />

            <SingleCard titulo_tarjeta="Cantidad Insumos Ingresados Durante el Mes"
              tarjeta_logo={<BiNotepad size={40} className='text-blue-500' />}
              title_card_style="text-blue-500 font-medium"
              color='border-blue-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${insumosIngresoT}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Salida Total de Insumos Durante el Mes"
              tarjeta_logo={<TbSquareArrowUp size={40} className='text-yellow-500' />}
              title_card_style="text-yellow-500 font-medium"
              color='border-yellow-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${insumoSalidaT}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />


            {/* PRODUCTOS */}

            <SingleCard titulo_tarjeta="Cantidad de Productos con Bajo o Nulo Stock"
              tarjeta_logo={<MdErrorOutline size={40} className='text-orange-500' />}
              title_card_style="text-orange-500 font-medium"
              color='border-orange-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${productosBajo}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Ganancia con Salida de Productos Durante el Mes"
              tarjeta_logo={<FaSackDollar size={40} className='text-lime-500' />}
              title_card_style="text-lime-500 font-medium"
              color='border-lime-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `S/. ${(Math.round(productosGanado * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Cantidad de Ingreso de Productos Durante el Mes"
              tarjeta_logo={<BiNotepad size={40} className='text-sky-500' />}
              title_card_style="text-sky-500 font-medium"
              color='border-sky-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${productosCantIngreso}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Merma de Productos Durante el Mes"
              tarjeta_logo={<CgTrash size={40} className='text-violet-500' />}
              title_card_style="text-violet-500 font-medium"
              color='border-violet-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${productosMerma}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />


            {/* CLIENTES */}

            <SingleCard titulo_tarjeta="Clientes Registrados Durante el Mes"
              tarjeta_logo={<HiUsers size={40} className='text-pink-500' />}
              title_card_style="text-pink-500 font-medium"
              color='border-pink-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${clientesFecha}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Clientes Registrados en Total Desde la Apertura"
              tarjeta_logo={<FaUsers size={40} className='text-rose-500' />}
              title_card_style="text-rose-500 font-medium"
              color='border-rose-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${clienteTotales}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />


            {/* SERVICIOS */}

            <SingleCard titulo_tarjeta="Servicios Completados Durante el Mes"
              tarjeta_logo={<FaUserCheck size={40} className='text-indigo-500' />}
              title_card_style="text-indigo-500 font-medium"
              color='border-indigo-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${serviciosCompletados}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Servicios Pendientes"
              tarjeta_logo={<MdPendingActions size={40} className='text-teal-500' />}
              title_card_style="text-teal-500 font-medium"
              color='border-teal-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${serviciosPendientes}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />


            {/* PROVEEDORES */}

            <SingleCard titulo_tarjeta="Proveedores Activos Desde la Apertura"
              tarjeta_logo={<FaTruckLoading size={40} className='text-fuchsia-500' />}
              title_card_style="text-fuchsia-500 font-medium"
              color='border-fuchsia-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${proveedoresActivos}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

            <SingleCard titulo_tarjeta="Proveedores Inactivos desde la Apertura"
              tarjeta_logo={<MdOutlineCarCrash size={40} className='text-purple-500' />}
              title_card_style="text-purple-500 font-medium"
              color='border-purple-500'
              data_card_style='text-slate-600 font-medium text-xl'
              contenido={!loadingFilter ? `${proveedoresInactivos}` : <RotatingLines
                strokeColor="gray"
                strokeWidth='3'
                width='22'
                radius="5"
                ariaLabel="lines-loading"
                className='me-2'
              />}
            />

          </div>

          <div className={`flex ${!loadingFilter ? 'justify-evenly' : 'justify-start'} flex-wrap mb-10`}>

            {/* {!loadingFilter ? */}
            <BarProductosDefec
              id="chartHorizontalBar1"
              titulo="Gráfico Demanda de Productos Defectuosos"
              fechaI={fechaFormatI}
              fechaF={fechaFormatF}
              labels={productosDefectuosos.map(producto => `${producto.nombre_producto} - ${producto.color} - ${producto.talla}`)}
              data={productosDefectuosos.map(producto => producto.total_cantidad_defectuosa)}
            />
            {/* : 
            <div role="status" class="p-2 border border-gray-100 rounded shadow animate-pulse md:p-6 dark:border-gray-700" style={{maxWidth: '1000px', width: '100%'}}>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
              <div class="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div class="flex items-baseline mt-4">
                <div class="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
                <div class="w-full h-56 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-700"></div>
                <div class="w-full h-64 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-700"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-700"></div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>} */}

          </div>

          <div className='flex justify-center'>
            <div className={`flex ${!loadingFilter ? 'justify-evenly' : 'justify-center'} flex-wrap mb-10 bg-white`} style={{ maxWidth: '1000px', width: '100%' }}>

              <div className='ignore-pdf flex items-center border border-gray-300 w-full p-2'>
                <label className='me-3' htmlFor='dropdown_years_line_invested_earn'>Filtro por año:</label>

                <select name="dropdown_years_line_invested_earn" id="dropdown_years_line_invested_earn"
                  className={`px-2 py-1 bg-[#F9F9F9] border border-[#D9D9D9] text-sm me-4
                  rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
                  ${errors.filtro_anio_line_invertido_ganancia ? 'border-red-500' : 'border-slate-300'}`}
                  value={anioLineInvGanDefecto}
                  onChange={(e) => 
                    {setAnioLineInvGanDefecto(e.target.value);}
                  }
                >

                  {uniqueYears.map((anio) => (
                    <option key={anio} value={anio}>
                      {anio}
                    </option>
                  ))}

                </select>

                <div className='ms-4'>
                <button
                  disabled={loadingFilter}
                  className={`bg-sky-500 text-white rounded shadow-sm px-4 py-1 flex items-center
                ${loadingFilter ? 'cursor-not-allowed opacity-50' : ''}
                hover:bg-sky-600 transition`}
                  onClick={getDashboardAll}>
                  {loadingFilter ?
                    <div className='flex items-center justify-center'>
                      <RotatingLines
                        strokeColor="white"
                        strokeWidth='3'
                        width='22'
                        radius="5"
                        ariaLabel="lines-loading"
                        className='me-2'
                      />
                      Filtrando
                    </div>
                    : <>
                      <CiFilter className='me-2' /> Filtrar por año
                    </>
                  }

                </button>
              </div>




                {errors.filtro_anio_line_invertido_ganancia && Array.isArray(errors.filtro_anio_line_invertido_ganancia) && renderErrorMessages(errors.filtro_anio_line_invertido_ganancia)}
              </div>

              <IngresoInsumosSalidasProductos
                id="chartLineChart1"
                titulo="Gráfico de Costo en Inversión de Insumos y Ganancia en Venta de Productos"
                texto={`Se muestra el costo invertido en soles en insumos y lo ganado en soles en venta de productos del año ${labelAnioFiltro}`}

                labels1={iInsumosLineChart.map(invinsumo => invinsumo.mes)}
                dataIngresosInsumos={iInsumosLineChart.map(invinsumo => invinsumo.precio_total)}

                labels2={gProductosLineChart.map(ganproductos => ganproductos.month)}
                dataGananciaProductos={gProductosLineChart.map(ganproductos => ganproductos.ganancia_mes)}
              />
            </div>
          </div>

          {/* <div className='page-break-tables' id="page_break_one"></div> */}

          <div className='flex flex-wrap justify-around max-[1688px]:justify-center me-3'>
            {/* TOP 5 Insumos más usados */}
            <div className='mt-16 overflow-auto'>
              <TopInsumosUsados array_insumos_top_salidas={topInsumosSalidas} fechaI={fechaFormatI} fechaF={fechaFormatF} />
            </div>
            <div className='mt-16 overflow-auto'>
              <InsumosConBajoStock array_insumos_bajo_stock={listaInsumosBajoStock} fechaI={fechaFormatI} fechaF={fechaFormatF} />
            </div>


            {/* TOP 5 Productos con más salidas */}
            <div className='mt-16 max-[1247px]:mt-5 overflow-auto'>
              <TopProductosSalidas array_productos_top_salidas={topProductosSalidas} fechaI={fechaFormatI} fechaF={fechaFormatF} />
            </div>

            <div className='mt-16 overflow-auto'>
              <ProductosConBajoStock array_productos_bajo_stock={listaProductosBajoStock} fechaI={fechaFormatI} fechaF={fechaFormatF} />
            </div>
          </div>

          {/* Calendario */}
          <div className='ignore-pdf'>
            <div className='bg-white rounded shadow-sm mt-8 mx-2 p-3'>
              <h3 className='p-2 underline text-green-700 font-semibold' style={{ fontSize: '18px' }}>Fechas de Ingresos y Salidas de Insumos</h3>
              <p className='p-2 font-normal text-gray-600 mb-2 italic' style={{ fontSize: '14px' }}>Se muestran los últimos 50 ingresos y salidas de insumos</p>
              <CalendarioInsumos dataInsumos={eventosInsumos} />
            </div>

            <div className='bg-white mt-8 rounded shadow-sm mx-2 p-3'>
              <h3 className='p-2 underline text-green-700 font-semibold' style={{ fontSize: '18px' }}>Fechas de Ingresos y Salidas de Productos</h3>
              <p className='p-2 font-normal text-gray-600 mb-2 italic' style={{ fontSize: '14px' }}>Se muestran los últimos 50 ingresos y salidas de productos</p>
              <CalendarioProductos dataProductos={eventosProductos} />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
