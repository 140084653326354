import React from 'react';
import BarraNavegacion from '../../components/navbarCliente/BarraNavegacion';
import FooterNav from '../../components/navbarCliente/FooterNav';

export default function ProductosMuestra() {
  return (
    <div className='min-h-screen bg-gray-100 flex flex-col'>
        
        <BarraNavegacion/>
        
        <div className="flex justify-center items-center flex-col text-white bg-green-700 w-full h-24">
          <h1 style={{ fontSize: '24px' }}>Productos</h1>
        </div>

        <div className='px-52'>

            <div>
                <h1 className='text-green-700 mb-10' style={{ fontSize: '20px' }}>Productos que Elaboramos</h1>
            </div>

            <div className='flex justify-between flex-wrap mb-10'>

                <div className='flex flex-col'>
                    <h5 className='font-semibold' style={{ fontSize: '18px' }}>Pantalones</h5>

                    <img className='shadow-xl border rounded' src='/img/productos/pantalones.jpg' alt='pantalones'
                    style={{ width: '300px', height: '300px' }}
                    />

                    <p className='mt-6'>Ofrecemos pantalones de todos los diseños</p>
                </div>

                <div className='flex flex-col'>
                    <h5 className='font-semibold' style={{ fontSize: '18px' }}>Polos</h5>

                    <img className='shadow-xl border rounded' src='/img/productos/polos.webp' alt='polos'
                    style={{ width: '300px', height: '300px' }}
                    />

                    <p className='mt-6'>Ofrecemos polos de todos los diseños</p>
                </div>

                <div className='flex flex-col'>
                    <h5 className='font-semibold' style={{ fontSize: '18px' }}>Sacos</h5>

                    <img className='shadow-xl border rounded' src='/img/productos/sacos.jpg' alt='sacos'
                    style={{ width: '300px', height: '300px' }}
                    />

                    <p className='mt-6'>Ofrecemos sacos de todos los diseños</p>
                </div>

            </div>

        </div>
        
        <FooterNav/>
        
    </div>
  )
}
