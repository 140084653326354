import React, { useEffect, useState } from 'react';

import Config from '../../../Config';
import AuthUser from '../../../pages/pagesAuth/AuthUser';
import InputForm from '../../inputs/InputForm';

import Modal from '../../modals/Modal';

import { RotatingLines, TailSpin } from 'react-loader-spinner';
import { MdEditDocument } from "react-icons/md";

import { toast } from 'react-toastify';

import AsyncSelect from 'react-select/async';

export default function ModalEditarProducto({ open, onClose, updateProductoList, editId }) {

    const { getRol } = AuthUser();

    const id = editId;

    const [categoriaProductoId, setCategoriaProductoId] = useState('');
    const [nombreProducto, setNombreProducto] = useState('');
    const [color, setColor] = useState('');
    const [talla, setTalla] = useState('');

    const [errors, setErrors] = useState({});

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            cargarProducto();
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const loadCategoriasProductosOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectCategoriaProductosAdmin(globalFilter);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectCategoriaProductosOperario(globalFilter);
            }
            const arrayCatProductos = response.data;
            if (arrayCatProductos.exito == 0) {
                return [];
            }

            return arrayCatProductos.map(categoria_producto => ({
                value: categoria_producto.id_catproducto,
                label: `${categoria_producto.id_catproducto} - ${categoria_producto.nombre_categoria_producto}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar las categorías de productos. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const cargarProducto = async () => {
        setLoadingData(true);
        setErrors({});

        try {

            let response;

            if (getRol() === "Administrador") {
                response = await Config.getOneProductoAdmin(id);
            } else if (getRol() === "Operario") {
                response = await Config.getOneProductoOperario(id);
            }

            console.log(response);

            if (response.data.exito == 0) {
                notify(`Error: ${response.data.message}`, "error");
            } else {

                setCategoriaProductoId(response.data.categoria_producto);
                setNombreProducto(response.data.nombre_producto);
                setColor(response.data.color);
                setTalla(response.data.talla);
            }

        } catch (error) {
            console.error(error);
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        } finally {
            setLoadingData(false);
        };
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadingButton(true);

        try {

            const productoData = {
                categoria_producto_id: (categoriaProductoId.id_catproducto ? categoriaProductoId.id_catproducto : categoriaProductoId.value),
                nombre_producto: nombreProducto,
                color: color,
                talla: talla,
            };

            let response;

            if (getRol() === "Administrador") {
                response = await Config.putUpdateProductoAdmin(id, productoData);
            } else if (getRol() === "Operario") {
                response = await Config.putUpdateProductoOperario(id, productoData);
            }
           

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(`Error: ${response.data.message}`, "error");
                } else if (response.data.exito == 1) {
                    notify("El producto ha sido editado correctamente.", "success");
                    setCategoriaProductoId('');
                    setNombreProducto('');
                    setColor('');
                    setTalla('');
                    onClose();
                    updateProductoList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }


        } catch (error) {
            console.log(error);
            updateProductoList();
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
            setLoadingButton(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div style={{ marginLeft: '136px' }}><p key={index} className="text-red-500 text-sm -mt-3 mb-3 ms-1">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Editar</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Editar Producto</h3>

                <form>

                    {!loadingData ?

                        <>

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='categoria_id' className='w-64 flex items-center font-normal text-[#454545]'>Categoría de Producto: </label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadCategoriasProductosOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.categoria_producto_id)}
                                    id="categoria_id"
                                    //value={categoriaProductoId}
                                    defaultValue={{ id: categoriaProductoId.id_catproducto, label: `${categoriaProductoId.id_catproducto} - ${categoriaProductoId.nombre_categoria_producto}` }}
                                    onChange={(option, id) => { setCategoriaProductoId(option); console.log(option, id); }}
                                    placeholder="Seleccionar categoría de insumo..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                />
                            </div>

                            {errors.categoria_producto_id && Array.isArray(errors.categoria_producto_id) && renderErrorMessages(errors.categoria_producto_id)}

                            <InputForm
                                id="producto"
                                labelName="Nombre del Producto:"
                                inputType="text"
                                value={nombreProducto}
                                funcion={(e) => setNombreProducto(e.target.value)}
                                errors={errors.nombre_producto}
                                labelWidth="w-64"
                            />

                            {errors.nombre_producto && Array.isArray(errors.nombre_producto) && renderErrorMessages(errors.nombre_producto)}

                            <InputForm
                                id="color"
                                labelName="Color:"
                                inputType="text"
                                value={color}
                                funcion={(e) => setColor(e.target.value)}
                                errors={errors.color}
                                labelWidth="w-64"
                            />

                            {errors.color && Array.isArray(errors.color) && renderErrorMessages(errors.color)}

                            <InputForm
                                id="talla"
                                labelName="Talla:"
                                value={talla}
                                funcion={(e) => setTalla(e.target.value)}
                                errors={errors.talla}
                                labelWidth="w-64"
                            />

                            {errors.talla && Array.isArray(errors.talla) && renderErrorMessages(errors.talla)}

                        </>

                        : <div className='flex justify-center items-center' style={{ height: '217px' }}>
                            <TailSpin className='' width={150} height={150}></TailSpin>
                        </div>}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingButton}
                            className={`flex gap-2 items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-52
                            text-white bg-green-600 me-3
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={onSubmit}
                            type="submit">

                            {loadingButton ?
                                <div className='flex items-center justify-center w-52'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <MdEditDocument /> Editar Producto
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
