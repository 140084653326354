import React, { useMemo } from 'react';
import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import {
    MaterialReactTable,
    useMaterialReactTable,
    Table,
    TableHead,
} from 'material-react-table';

import {
    Typography,
    Stack,
} from '@mui/material';

export default function ProductosConBajoStock({ array_productos_bajo_stock, fechaI, fechaF }) {

    const columns = useMemo(
        () => [
            //{
            //    accessorKey: "id", //simple recommended way to define a column
            //    header: "ID",
            //    size: 10
            /* muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
            Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render */
            //},
            {
                id: "#",
                header: "Nro.",
                size: 1,
                Cell: ({ row }) => {
                    return row.index + 1;
                }
            },
            {
                accessorFn: (row) => row.codigo,
                id: "codigo",
                header: "Código",
                size: 150,
                grow: false,
            },
            {
                accessorFn: (row) => row.nombre_producto,
                id: "nombre_producto",
                header: "Producto",
                size: 150,
                grow: false,
            },
            {
                accessorFn: (row) => row.color,
                id: "color",
                header: "Color",
                size: 150,
                grow: false,
            },
            {
                accessorFn: (row) => row.talla,
                id: "talla",
                header: "Talla",
                size: 150,
                grow: false,
            },
            {
                accessorFn: (row) => row.saldo,
                id: "saldo",
                header: "Cantidad",
                size: 150,
                grow: false,
            },
        ],
        []
    );

    const data = (array_productos_bajo_stock ? array_productos_bajo_stock : []);

    const table = useMaterialReactTable({
        columns,
        data,

        /* enableColumnResizing: true,
        enableColumnPinning: true,
        enableRowNumbers: true,
        columnResizeMode: 'onEnd',
        layoutMode: 'grid', */
        muiTableContainerProps: { sx: { minHeight: '300px', maxHeight: '300px', minWidth: '950px', maxWidth: '980px', width: '100%' } },

        muiTableHeadCellProps: {
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
                backgroundColor: 'rgb(51, 154, 91)',
                color: 'white',
                border: '1px solid #edf0f5'
            },
        },

        muiTableBodyCellProps: {
            sx: {
                border: '1px solid #edf0f5' // agrega borde a las celdas del cuerpo de la tabla
            },
        },

        enableBottomToolbar: false,

        enableColumnActions: false,
        enableColumnFilters: false,
        enablePagination: false,
        enableSorting: false,

        enableGlobalFilter: false,
        enableColumnFilters: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnFilterModes: false,

        defaultColumn: {
            maxSize: 9000,
            minSize: 50,
            size: 50, //default size is usually 180
        },

        initialState: {
            showColumnFilters: false,
            density: 'compact',
            columnVisibility: {
                color: false
            }
        },


        localization: MRT_Localization_ES,


    });

  return (
    <div className='w-full max-[1247px]:w-full'>

            <div className='relative bg-white'>
                <h3 className='relative p-2 underline text-green-700 font-semibold' style={{ fontSize: '18px' }}>Lista de Productos con Bajo Stock en Total</h3>
                <p className='relative p-2 text-green-700 font-semibold'>desde el {fechaI} hasta {fechaF}</p>
                <p className='relative p-2 text-gray-600 font-semibold text-sm'>Se muestran insumos con cantidades menores o iguales a 30</p>
                <MaterialReactTable
                    table={table}
                />
            </div>

        </div>
  )
}
