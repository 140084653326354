import React, { useState } from 'react';
import * as XLSX from "xlsx";
import * as XLSXStyle from "xlsx-js-style";

import { RotatingLines } from 'react-loader-spinner';
import { FaFileCsv } from 'react-icons/fa';

export default function KardexProductosExcel({ kardexProductosData }) {

    const [loadingDataExcel, setLoadingDataExcel] = useState(false);

    const getFormattedDate = () => {
        const date = new Date();
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        };
        return date.toLocaleString('es-ES', options);
    };

    const getFormattedHour = () => {
        const date = new Date();
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };
        return date.toLocaleString('es-ES', options);
    };

    const titulo = [{ A: "Reporte | Kardex de Productos"}, {}];

    const InfoAdicional = {
        A: "Generado por Jossmary Web"
    };

    const FechaActual = {
        A: `Reporte generado el ${getFormattedDate()} a las ${getFormattedHour()}`
    };

    const longitudes = [5, 15, 25, 18, 15, 20, 25, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20];

    const handleExcelDownload = () => {
        setLoadingDataExcel(true);

        let table = [
            {
                A: "Nro.",
                B: "Movimiento",
                C: "Código",
                D: "Producto",
                E: "Color",
                F: "Talla",
                G: "Can. Ingreso",
                H: "Can. Defectuosa",
                I: "Can. Resto",
                J: "Precio Unitario S/.",
                K: "Precio Total S/.",
                L: "Fecha de Ingreso",
                M: "Nombre | Ingresante",
                N: "Email | Ingresante",
                O: "Rol | Ingresante",
                P: "Cantidad Salida",
                Q: "Fecha de Salida",
                R: "Nombre | Salida",
                S: "Email | Salida",
                T: "Rol | Salida",
            },
            ...kardexProductosData.map((kardexProducto, index) => ({
                A: index+1,
                B: kardexProducto.es_ingreso_producto == 1 ? 'Ingreso' : 'Salida',
                C: kardexProducto.codigo,
                D: kardexProducto.nombre_producto,
                E: kardexProducto.color,
                F: kardexProducto.talla,
                G: kardexProducto.cantidad_ingreso,
                H: kardexProducto.cantidad_defectuosa,
                I: kardexProducto.cantidad_resto_ingreso,
                J: kardexProducto.precio_unitario,
                K: kardexProducto.precio_total,
                L: kardexProducto.fecha_ingreso ? dateFormatter(kardexProducto.fecha_ingreso) : null,
                M: kardexProducto.nombre_ingreso,
                N: kardexProducto.email_ingreso,
                O: kardexProducto.rol_ingreso,
                P: kardexProducto.cantidad_salida,
                Q: kardexProducto.fecha_salida ? dateFormatter(kardexProducto.fecha_salida) : null,
                R: kardexProducto.nombre_salida,
                S: kardexProducto.email_salida,
                T: kardexProducto.rol_salida,
            }))
        ];

        const dataFinal = [...titulo, FechaActual, {}, ...table, {}, InfoAdicional];

        setTimeout(() => {
            creandoArchivo(dataFinal);
            setLoadingDataExcel(false);
        }, 1000);
    }

    const creandoArchivo = (dataFinal) => {
        const libro = XLSX.utils.book_new();

        const hoja = XLSX.utils.json_to_sheet(dataFinal, {skipHeader: true});

        //Unir celdas
        hoja["!merges"] = [
            XLSX.utils.decode_range("A1:T1"),
            XLSX.utils.decode_range("A2:T2"),
        ];

        // Estilo para A1:G1
        const tituloStyle = {
            font: { bold: true, color: { rgb: "33691E" }, sz: 18 },
            //fill: { fgColor: { rgb: "C5E1A5" } },
            alignment: { horizontal: "center", vertical: "center" }
        };

        // Aplicar estilo a A1:G1
        const tituloCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T'];
        tituloCols.forEach(col => {
            const cell = `${col}1`;
            if (hoja[cell]) {
                hoja[cell].s = tituloStyle;
            }
        });

        //Aplicar estilos a A3
        const reporteStyle = {
            font: { bold: true, color: { rgb: "33691E" }, sz: 10 },
        };

        //Aplicar estilos a A3
        const reporteCols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T'];
        reporteCols.forEach(col => {
            const cell = `${col}3`;
            if (hoja[cell]) {
                hoja[cell].s = reporteStyle;
            }
        });

        // Agregar estilo a los encabezados A5:S5
        const headerStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "4CAF50" } },
            alignment: { horizontal: "center", vertical: "center" }
        };

        // Aplicar estilo a los encabezados
        const cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T'];
        cols.forEach(col => {
            const cell = `${col}5`;
            if (hoja[cell]) {
                hoja[cell].s = headerStyle;
            }
        });

        // Aplicar estilos condicionales a las celdas "MOVIMIENTO"
        dataFinal.forEach((row, rowIndex) => {
            if (rowIndex > 4 && row.B) {  // Saltar encabezados y título
                const cell = `B${rowIndex + 1}`;
                const isActive = row.B === 'Ingreso';  // Verificar si el estado es 'Activo'
                const style = {
                    fill: { fgColor: { rgb: isActive ? "218014" : "801414" } },
                    font: { color: { rgb: "FFFFFF" } }
                };
                if (hoja[cell]) {
                    hoja[cell].s = style;
                }
            }
        });

        let propiedades = [];

        longitudes.forEach((col) => {
            propiedades.push({
                width: col,
            });
        });

        hoja["!cols"] = propiedades;

        XLSXStyle.utils.book_append_sheet(libro, hoja, "Kardex Productos");

        XLSXStyle.writeFile(libro, `excel_kardex_productos_${new Date().toLocaleDateString()}.xlsx`);
    };

    const dateFormatter = (dateString) => {
        const parsedDate = new Date(dateString).toLocaleString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        return parsedDate;
    };

  return (
    <div>
        <button disabled={loadingDataExcel}
        className={`max-[495px]:ms-1 max-[467px]:ms-0 max-[315px]:mt-3
            ${loadingDataExcel ? 'bg-lime-600 text-white cursor-not-allowed opacity-50 rounded border border-lime-600' :
            `bg-white rounded text-lime-600 
            border border-lime-600
            hover:text-white hover:bg-lime-600 `}
            px-2 py-1 flex items-center 
            `}
               onClick={handleExcelDownload}
        >
            {loadingDataExcel ?
                        <div className='flex items-center justify-center'>
                            <RotatingLines
                                strokeColor="white"
                                strokeWidth='3'
                                width='22'
                                radius="5"
                                ariaLabel="lines-loading"
                            />
                            <p className='ms-3'>Exportando...</p>
                        </div>
                        : <>
                            <FaFileCsv className='me-3' /> Exportar Excel
                        </>
                    }
        </button>
    </div>
  )
}
