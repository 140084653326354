import React, { useState, useEffect } from 'react'
import Config from '../../Config';

import { RotatingLines } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';

import VerificationInput from "react-verification-input";
import { toast } from 'react-toastify';

export default function ValidarCodigo() {

    const navigate = useNavigate();

    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    const [codigo, setCodigo] = useState('');
    const [email, setEmail] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!sessionStorage.getItem('correo_recuperar')) {
            navigate('/recuperar-password');
        }
        setEmail(sessionStorage.getItem('correo_recuperar'));
    }, [email, navigate]);

    const handleCodigoChange = (value) => {
        setCodigo(value);
    };

    const handleSubmitCodigo = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});

        try {
            const response = await Config.postValidarCodigo({ email:email, code: codigo });

            console.log(response);
            setMessage(response.data.message);

            // Si está todo bien, se continúa con enviar al formulario
            if (response.data.cambiar_contra === "0") {
                navigate('/cambiar-password');
                notifySuccess("Código validado. Cambie su contraseña.");
            } else {
                if(response.data.errors){
                    setErrors(response.data.errors);
                }
                notify(response.data.message);
            }
        } catch (error) {
            console.error('Error al validar el código:', error);
            notify("Ha ocurrido un error. Vuelva a intentarlo en unos minutos.");
        } finally {
            setLoading(false);
        }
    };

    const notifySuccess = (mensaje) => toast.success(mensaje, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const notify = (mensaje) => toast.error(mensaje, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    return (
        <div>
            <div className='h-screen bg-gray-100 flex items-center justify-center'>
                <div className='bg-white w-96 p-6 rounded-md shadow-lg'>

                    <div className='text-center text-xl mb-2 text-gray-500 font-semibold'><h1>Validar Código</h1></div>

                    <p className='text-gray-500 my-4 text-sm'>Ingrese el código recibido en su correo</p>

                    {message ? <p className="text-red-500 text-sm my-3">{message}</p> : ''}
                    {errors.email && <p className="text-red-500 text-sm">{errors.email[0]}</p>}

                    <form>

                        <VerificationInput
                            validChars={'0-9'}
                            placeholder={''}
                            autoFocus={true}
                            length={6}
                            onChange={handleCodigoChange}
                            classNames={{
                                container: "container",
                                character: "character",
                                characterInactive: "character--inactive",
                                characterSelected: "character--selected",
                                characterFilled: "character--filled",
                            }}
                        />

                        {errors.code && <p className="text-red-500 text-sm">{errors.code[0]}</p>}

                        <p className='text-gray-500 mb-5 mt-0 text-sm'>El código tiene una duración de 10 minutos</p>
                        <p className='text-gray-500 mb-1 text-sm'>¿No has recibido el código? <Link to='/' className='text-blue-400 underline cursor-pointer'>Reenviar código</Link> </p>

                        <button type="submit"
                            disabled={loading} // Desactiva el botón mientras se carga
                            className={`bg-green-600 w-full mt-0
                                text-white py-2 rounded-md hover:bg-green-700
                                transition-colors font-semibold leading-6
                                focus:ring-4 focus:outline-none focus:ring-green-600 mx-auto block ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
                            onClick={handleSubmitCodigo}
                        >
                            {loading ?
                                <div className='flex items-center justify-center'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                :
                                'Validar código'}
                        </button>

                        <Link className={`bg-gray-400 w-full mt-0 text-center mt-1
                                text-white py-2 rounded-md hover:bg-gray-500
                                transition-colors font-semibold leading-6
                                focus:ring-4 focus:outline-none focus:ring-gray-400 mx-auto block ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
                            to="/recuperar-password">
                            Volver
                        </Link>



                        <hr className='mt-5 h-0.5 border-none bg-gray-200'></hr>

                        <div className='text-center mt-2'>
                            <p className='text-gray-400 text-sm'>¿Desea Ingresar al Sistema? <Link to='/' className='text-blue-400 underline cursor-pointer'>Iniciar Sesión</Link> </p>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}
