import React, { useEffect, useState } from 'react';

import Config from '../../../Config';
import AuthUser from '../../../pages/pagesAuth/AuthUser';
import InputForm from '../../inputs/InputForm';

import Modal from '../../modals/Modal';

import { RotatingLines, TailSpin } from 'react-loader-spinner';
import { MdEditDocument } from "react-icons/md";

import { toast } from 'react-toastify';

import Select from 'react-select';

export default function ModalEditarCliente({
    open, onClose, updateClienteList, editId
}) {

    const { getRol } = AuthUser();

    const id = editId;

    //Select import
    const [arrayTipoClientes, setArrayTipoClientes] = useState([]);
    const [arrayTipoDocumentos, setArrayTipoDocumentos] = useState([]);
    const [arrayDistritos, setArrayDistritos] = useState([]);

    const [tipoCliente, setTipoCliente] = useState('');
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [distrito, setDistrito] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [apellidoMaterno, setApellidoMaterno] = useState('');
    const [nro_documento, setNro_documento] = useState('');
    const [direccion, setDireccion] = useState('');
    const [celular, setCelular] = useState('');

    const [errors, setErrors] = useState({});

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {

        getTiposClientesAll();
        getTiposDocumentosAll();
        getDistritosAll();

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            cargarCliente();
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const getTiposClientesAll = async () => {
        let getTipoClientesList;

        if (getRol() === "Administrador") {
            getTipoClientesList = Config.getTipoClientesAdmin();
        }

        try {
            const response = await getTipoClientesList;
            //console.log("tipos de clientes: ", response.data);
            setArrayTipoClientes(response.data);
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los tipos de clientes. Vuelva a intentarlo más tarde.", "error");
        } finally {

        }
    }

    const getTiposDocumentosAll = async () => {
        let getTipoDocumentosList;

        if (getRol() === "Administrador") {
            getTipoDocumentosList = Config.getTipoDocumentosAdmin();
        }

        try {
            const response = await getTipoDocumentosList;
            //console.log("tipos de documentos: ", response.data);
            setArrayTipoDocumentos(response.data);
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los tipos de documentos. Vuelva a intentarlo más tarde.", "error");
        } finally {

        }
    }

    const getDistritosAll = async () => {
        let getDistritosList;

        if (getRol() === "Administrador") {
            getDistritosList = Config.getDistritosAdmin();
        }

        try {
            const response = await getDistritosList;
            //console.log("tipos de documentos: ", response.data);
            setArrayDistritos(response.data);
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los distritos. Vuelva a intentarlo más tarde.", "error");
        } finally {

        }
    }

    const optionsTipoClientes = arrayTipoClientes.map(tipo_cliente => ({
        value: tipo_cliente.id,
        label: `${tipo_cliente.id} - ${tipo_cliente.nombre_tipo}`
    }));

    const optionsTipoDocumentos = arrayTipoDocumentos.map(tipo_documento => ({
        value: tipo_documento.id,
        label: `${tipo_documento.nombre_documento}`
    }));

    const optionsDistritos = arrayDistritos.map(distrito => ({
        value: distrito.id,
        label: `${distrito.nombre_distrito}`
    }));

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const cargarCliente = async () => {
        setLoadingData(true);
        setErrors({});

        try {

            let response;

            if (getRol() === "Administrador") {
                response = await Config.getOneClienteAdmin(id);
            }

            if (response.data.exito == 0) {
                notify(`Error: ${response.data.message}`, "error");
            } else {

                setTipoDocumento(response.data.tipo_documento);
                setTipoCliente(response.data.tipo_cliente);
                setDistrito(response.data.distrito);
                setNombres(response.data.nombres);
                setApellidoPaterno(response.data.apellido_paterno);
                setApellidoMaterno(response.data.apellido_materno);
                setNro_documento(response.data.nro_documento);
                setDireccion(response.data.direccion);
                setCelular(response.data.celular);

                console.log(tipoDocumento);

            }

        } catch (error) {
            console.error(error);
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        } finally {
            setLoadingData(false);
        };
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadingButton(true);

        try {

            const clienteData = {
                tipo_cliente_id: (tipoCliente.id ? tipoCliente.id : tipoCliente.value),
                tipo_documento_id: (tipoDocumento.id ? tipoDocumento.id : tipoDocumento.value),
                distrito_id: (distrito.id ? distrito.id : distrito.value),
                nombres: nombres,
                apellido_paterno: apellidoPaterno,
                apellido_materno: apellidoMaterno,
                nro_documento: nro_documento,
                direccion: direccion,
                celular: celular,
            };

            let response;

            if (getRol() === "Administrador") {
                response = await Config.putUpdateClienteAdmin(id, clienteData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(`Error: ${response.data.message}`, "error");
                } else if (response.data.exito == 1) {
                    notify("El cliente ha sido editado correctamente.", "success");
                    setTipoCliente('');
                    setTipoDocumento('');
                    setDistrito('');
                    setNombres('');
                    setApellidoPaterno('');
                    setApellidoMaterno('');
                    setNro_documento('');
                    setDireccion('');
                    setCelular('');
                    onClose();
                    updateClienteList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }


        } catch (error) {
            console.log(error);
            updateClienteList();
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
            setLoadingButton(false);
        };

    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div style={{ marginLeft: '136px' }}><p key={index} className="text-red-500 text-sm -mt-3 mb-3 ms-1">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Editar</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Editar Cliente</h3>

                <form>

                    {!loadingData ?

                        <>

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='tipo_documento' className='w-44 flex items-center font-normal text-[#454545]'>Tipo Documento: </label>
                                <Select options={optionsTipoDocumentos}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.tipo_documento_id)}
                                    id="tipo_documento"
                                    /* value={tipoDocumento.value} */
                                    defaultValue={{id: tipoDocumento.id, label:tipoDocumento.nombre_documento}}
                                    onChange={(option, id) => { setTipoDocumento(option); console.log(option, id) }}
                                    required
                                    placeholder="Seleccionar documento..."
                                    noOptionsMessage={() => ""}
                                    loadingMessage={() => "Cargando..."}
                                />
                            </div>

                            {errors.tipo_documento_id && Array.isArray(errors.tipo_documento_id) && renderErrorMessages(errors.tipo_documento_id)}

                            <InputForm
                                id="nro_documento"
                                labelName="N° de documento:"
                                inputType="text"
                                value={nro_documento}
                                funcion={(e) => setNro_documento(e.target.value)}
                                errors={errors.nro_documento}
                                labelWidth="w-44"
                            />

                            {errors.nro_documento && Array.isArray(errors.nro_documento) && renderErrorMessages(errors.nro_documento)}


                            <InputForm
                                id="nombres"
                                labelName="Nombres:"
                                inputType="text"
                                value={nombres}
                                funcion={(e) => setNombres(e.target.value)}
                                errors={errors.nombres}
                                labelWidth="w-44"
                            />

                            {errors.nombres && Array.isArray(errors.nombres) && renderErrorMessages(errors.nombres)}

                            <InputForm
                                id="apellido_paterno"
                                labelName="Apellido Paterno:"
                                inputType="text"
                                value={apellidoPaterno}
                                funcion={(e) => setApellidoPaterno(e.target.value)}
                                errors={errors.apellido_paterno}
                                labelWidth="w-44"
                            />

                            {errors.apellido_paterno && Array.isArray(errors.apellido_paterno) && renderErrorMessages(errors.apellido_paterno)}

                            <InputForm
                                id="apellido_materno"
                                labelName="Apellido Materno:"
                                inputType="text"
                                value={apellidoMaterno}
                                funcion={(e) => setApellidoMaterno(e.target.value)}
                                errors={errors.apellido_materno}
                                labelWidth="w-44"
                            />

                            {errors.apellido_materno && Array.isArray(errors.apellido_materno) && renderErrorMessages(errors.apellido_materno)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='tipo_documento' className='w-44 flex items-center font-normal text-[#454545]'>Distrito: </label>
                                <Select options={optionsDistritos}
                                    className='w-full'
                                    styles={getColourStyles(!!errors.distrito_id)}
                                    id="tipo_documento"
                                    /* value={{id: distrito.id, label:distrito.nombre_distrito}} */
                                    defaultValue={{id: distrito.id, label:distrito.nombre_distrito}}
                                    onChange={(option) => setDistrito(option)}
                                    required
                                    placeholder="Seleccionar distrito..."
                                    noOptionsMessage={() => ""}
                                    loadingMessage={() => "Cargando..."}
                                />
                            </div>

                            {errors.distrito_id && Array.isArray(errors.distrito_id) && renderErrorMessages(errors.distrito_id)}

                            <InputForm
                                id="direccion"
                                labelName="Dirección:"
                                inputType="text"
                                value={direccion}
                                funcion={(e) => setDireccion(e.target.value)}
                                errors={errors.direccion}
                                labelWidth="w-44"
                            />

                            {errors.direccion && Array.isArray(errors.direccion) && renderErrorMessages(errors.direccion)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='tipo_cliente' className='w-44 flex items-center font-normal text-[#454545]'>Tip. de Cliente: </label>
                                <Select options={optionsTipoClientes}
                                    className='w-full'
                                    styles={getColourStyles(!!errors.tipo_cliente_id)}
                                    id="tipo_cliente"
                                    /* value={{id: tipoCliente.id, label:tipoCliente.nombre_tipo}} */
                                    defaultValue={{id: tipoCliente.id, label:tipoCliente.nombre_tipo}}
                                    onChange={(option) => setTipoCliente(option)}
                                    required
                                    placeholder="Seleccionar tipo del cliente..."
                                    noOptionsMessage={() => ""}
                                    loadingMessage={() => "Cargando..."}
                                />
                            </div>

                            {errors.tipo_cliente_id && Array.isArray(errors.tipo_cliente_id) && renderErrorMessages(errors.tipo_cliente_id)}

                            <InputForm
                                id="celular"
                                labelName="Celular:"
                                inputType="text"
                                value={celular}
                                funcion={(e) => setCelular(e.target.value.replace(/[^0-9]/g, ""))}
                                errors={errors.celular}
                                maxLength={9}
                                labelWidth="w-44"
                            />

                            {errors.celular && Array.isArray(errors.celular) && renderErrorMessages(errors.celular)}

                        </>

                        : <div className='flex justify-center items-center' style={{ height: '217px' }}>
                            <TailSpin className='' width={150} height={150}></TailSpin>
                        </div>}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingButton}
                            className={`flex gap-2 items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-52
                            text-white bg-green-600 me-3
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={onSubmit}
                            type="submit">

                            {loadingButton ?
                                <div className='flex items-center justify-center w-52'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <MdEditDocument /> Editar Proveedor
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
