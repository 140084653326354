import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography, TextField } from '@mui/material';

import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Config from '../../../Config';

import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';

import { FaCircle } from "react-icons/fa";
import KardexServiciosPdf from '../../createPdf/KardexServicios/KardexServiciosPdf';
import KardexServiciosExcel from '../../createExcel/KardexServiciosExcel/KardexServiciosExcel';

export default function KardexServiciosTable() {

  const { getRol } = AuthUser();

  const [kardexServicios, setKardexServicios] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const [rowCount, setRowCount] = useState(0);

  // Función para formatear la fecha
  const formatFechaForDB = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = "00";
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const now = new Date();
  const nowLocalString = formatFechaForDB(now);

  //console.log("Hora para limit input ",nowLocalString);

  // Fecha inicial: primer día del mes actual
  const fechaInicialDefault = new Date(now.getFullYear(), now.getMonth(), 1);

  const [fechaInicial, setFechaInicial] = useState(formatFechaForDB(fechaInicialDefault));
  const [fechaFinal, setFechaFinal] = useState(formatFechaForDB(now));

  //Filtrar por ingresos y/o salidas
  const [mostrarIngreso, setMostrarIngreso] = useState(0);
  const [mostrarSalida, setMostrarSalida] = useState(0);

  const [errors, setErrors] = useState({});

  const renderErrorMessages = (messages) => {
    return messages.map((msg, index) => (
      <div key={index} style={{ marginLeft: '162px' }}>
        <p className="text-red-500 text-sm -mt-3 mb-3">{msg}</p>
      </div>
    ));
  };

  //Para actualizar luego de agregar o editar
  const updateServicioList = async () => {
    await getKardexServiciosAll();
  };

  useEffect(() => {
    getKardexServiciosAll();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    columnFilters,
    globalFilter,
    sorting,
    fechaInicial,
    fechaFinal, 
    mostrarIngreso,
    mostrarSalida
  ]);

  const getKardexServiciosAll = async () => {
    setTableLoading(true);

    let getKardexServiciosList;

    console.log(fechaInicial);
    console.log(fechaFinal);

    if (getRol() === "Administrador") {
      getKardexServiciosList = Config.getKardexServiciosListAdmin(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        fechaInicial,
        fechaFinal, 
        mostrarIngreso,
        mostrarSalida
      );
    } else if (getRol() === "Operario") {
      getKardexServiciosList = Config.getKardexServiciosListOperario(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        fechaInicial,
        fechaFinal, 
        mostrarIngreso,
        mostrarSalida
      );
    } else if (getRol() === "Almacenero") {
      getKardexServiciosList = Config.getKardexServiciosListAlmacenero(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        fechaInicial,
        fechaFinal, 
        mostrarIngreso,
        mostrarSalida
      );
    } else if (getRol() === "Gerente") {
      getKardexServiciosList = Config.getKardexServiciosListGerente(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting,
        fechaInicial,
        fechaFinal, 
        mostrarIngreso,
        mostrarSalida
      );
    }

    try {
      const response = await getKardexServiciosList;
      console.log(response);
      if (response.data.exito == '0') {
        notify(response.data.message, 'error')
      } else {
        setKardexServicios(response.data.data);
        setRowCount(response.data.total);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTableLoading(false);
    }
  }

  const notify = (message, type) => {
    toast[type](message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };  

  // COLUMNAS DE LA TABLA

  const columns = useMemo(
    () => [
      {
        id: "#",
        header: "Nro.",
        size: 1,
        Cell: ({ row }) => {
          return row.index + 1;
        }
      },
      {
        accessorFn: (row) => (row.es_ingreso_servicio ? row.es_ingreso_servicio : 0),
        id: "movimiento_servicio",
        header: "Movimiento",
        size: 5,
        Cell: ({ cell }) => (
          <Box
          component="span"
          sx={(theme) => ({
            backgroundColor:
                cell.getValue() == "1"
                    ? theme.palette.success.dark
                    : cell.getValue() == "0"
                        ? theme.palette.error.dark
                        : theme.palette.secondary.dark,
            borderRadius: '0.25rem',
            color: "white",
            p: "0.25rem"
        })}          
          >
            {cell.getValue() == "1" ? "Ingreso" : 'Salida'}
          </Box>
        )
      },
      {
        id: 'info_producto',
        header: 'Info. del Servicio',
        columns: [
          {
            accessorFn: (row) => (row.codigo ? row.codigo : '-'),
            id: "codigo",
            header: "Código",
            size: 5
          },
          {
            accessorFn: (row) => (row.nombre_servicio ? row.nombre_servicio : '-'),
            id: "nombre_servicio",
            header: "Servicio",
            size: 5
          },
          {
            accessorFn: (row) => (row.descripcion ? row.descripcion : '-'),
            id: "descripcion",
            header: "Descripción",
            size: 5
          },
        ]
      },
      {
        id: 'info_responsable',
        header: 'Info. del Responsable Merma',
        columns: [
          {
            accessorFn: (row) => (row.nombre_responsable ? row.nombre_responsable : '-'),
            id: "nombre_responsable",
            header: "N. Responsable",
            size: 5
          },
          {
            accessorFn: (row) => (row.email_responsable ? row.email_responsable : '-'),
            id: "email_responsable",
            header: "Email Responsable",
            size: 5
          },
          {
            accessorFn: (row) => (row.rol_responsable ? row.rol_responsable : '-'),
            id: "rol_responsable",
            header: "Rol Responsable",
            size: 5
          },
        ]
      },
      {
        id: 'info_ingreso',
        header: 'Info. del Ingreso Servicio',
        columns: [
          {
            accessorFn: (row) => row.precio_unitario_serv,
            id: "precio_unitario_serv",
            header: "P. Unitario S/.",
            size: 1,
          },
          {
            accessorFn: (row) => (row.cantidad_ingreso_serv ? row.cantidad_ingreso_serv : null),
            id: "cantidad_ingreso_serv",
            header: "Can. Ingreso",
            size: 1,
          },
          {
            accessorFn: (row) => row.merma_servicios,
            id: "merma_servicios",
            header: "Can. Merma",
            size: 1,
          },
          {
            accessorFn: (row) => row.cantidad_final_serv,
            id: "cantidad_final_serv",
            header: "Can. Final",
            size: 1,
          },
          {
            accessorFn: (row) => row.precio_final_serv,
            id: "precio_final_serv",
            header: "P. Total S/.",
            size: 1,
          },
          {
            accessorFn: (row) => (row.fecha_ingreso_serv ? new Date(row.fecha_ingreso_serv) : null),
            id: "fecha_ingreso_serv",
            header: "Fecha de Ingreso",
            filterVariant: 'datetime-range',
            Cell: ({ cell }) => {
              const date = new Date(cell.getValue());
              return cell.getValue() ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
                : null;
            },
          },
        ]

      },
      {
        id: 'info_responsable_ingreso',
        header: 'Responsable del Ingreso',
        columns: [
          {
            accessorFn: (row) => row.nombre_ingreso,
            id: "nombre_ingreso",
            header: "N. Ingreso",
            size: 2
          },
          {
            accessorFn: (row) => row.email_ingreso,
            id: "email_ingreso",
            header: "Email Ingreso",
            size: 2
          },
          {
            accessorFn: (row) => row.rol_ingreso,
            id: "rol_ingreso",
            header: "Rol Ingreso",
            size: 2
          },
        ]

      },
      {
        id: 'info_salida',
        header: 'Info. de Salida',
        columns: [
          {
            accessorFn: (row) => row.cantidad_salida_serv,
            id: "cantidad_salida_serv",
            header: "Can. Salida",
            size: 1,
          },
          {
            accessorFn: (row) => (row.fecha_salida_serv ? new Date(row.fecha_salida_serv) : null),
            id: "fecha_salida_serv",
            header: "Fecha de Salida",
            filterVariant: 'datetime-range',
            Cell: ({ cell }) => {
              const date = new Date(cell.getValue());
              return cell.getValue() ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
                : null;
            },
          },

        ]
      },
      {
        id: 'info_responsable_salida',
        header: 'Responsable de Salida',
        columns: [
          {
            accessorFn: (row) => (row.nombre_salida ? row.nombre_salida : null),
            id: "nombre_salida",
            header: "N. Salida:",
            size: 2
          },
          {
            accessorFn: (row) => (row.email_salida ? row.email_salida : null),
            id: "email_salida",
            header: "Email Salida:",
            size: 2
          },
          {
            accessorFn: (row) => (row.rol_salida ? row.rol_salida : null),
            id: "rol_salida",
            header: "Rol Salida:",
            size: 2
          },
        ]
      },
    ],
    []
  );

  const data = (kardexServicios ? kardexServicios : []);

  const table = useMaterialReactTable({
    columns,
    data,

    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20, 50, 100, 500, 1000],
      showFirstButton: true,
      showLastButton: true,
    },

    enableSorting: false,
    enableColumnFilters: false,

    enableExpandAll: false, //disable expand all button
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255,210,244,0.1)'
            : 'rgba(0,0,0,0.1)',
      }),
    }),
    //custom expand button rotation
    /* muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),

    renderDetailPanel: ({ row }) =>
      row.original.usuario ? (
        <Box
          sx={{
            display: '',
            margin: 'auto',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
          }}
        >
          <Typography><span className='font-bold'>Ingresado por:</span></Typography>
          <Typography><span className='font-bold'>- Nombre:</span> {row.original.usuario.name}</Typography>
          <Typography><span className='font-bold'>- Correo:</span> {row.original.usuario.email}</Typography>
          <Typography><span className='font-bold'>- Rol:</span> {row.original.usuario.role.rol}</Typography>
        </Box>
      ) : null, */

    initialState: {
      showColumnFilters: false,
      density: 'compact',
      columnVisibility: {
        email: false,
        rol: false,
      },
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,

    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,

    state: {
      columnFilters,
      globalFilter,
      isLoading: tableLoading,
      pagination,
      sorting,
    },

    localization: MRT_Localization_ES,
    muiSearchTextFieldProps: {
      placeholder: 'Buscar todos los ingresos de insumos',
      sx: { minWidth: '300px' },
      variant: 'outlined',
    },

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ display: 'flex', gap: '16px' }}>

          {getRol() === "Administrador" || getRol() === "Gerente" ?
          <>

          <KardexServiciosExcel kardexServiciosData={kardexServicios} />
          <KardexServiciosPdf kardexServiciosData={kardexServicios} />
          </>
          : null}
        </Box>

        <Box sx={{
          display: 'flex',          
          padding: '0px',
          paddingX: '8px',
          alignItems: 'start',
          justifyContent: 'center',
          marginTop: '-5px',
          marginBottom: '10px',
          flexDirection: 'column'
        }}>
          <p variant="subtitle1" className='flex items-center justify-center mb-2 underline'>Filtro por fechas: </p>
          <Box sx={{ display: 'flex', gap: '16px', borderTopRightRadius: '8px', borderBottomRightRadius: '8px',}}>
            <div className='flex'>

              <label htmlFor="date_picker" className={`w-56 flex items-center font-normal text-[#454545] text-sm`} style={{ width: "100px" }}>Fecha de Ini.:</label>
              <input
                value={fechaInicial || ""}
                onChange={(e) => { setFechaInicial(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value));  }}

                disabled={tableLoading}

                type="datetime-local"
                max={fechaFinal}
                className={`px-2 py-0 bg-[#F9F9F9] border border-[#D9D9D9] text-sm
rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B]
${tableLoading ? 'opacity-50 cursor-not-allowed': ''}
${errors.fecha_ingreso ? 'border-red-500' : 'border-slate-300'}`}
              />

            </div>

            {errors.fecha_ingreso && Array.isArray(errors.fecha_ingreso) && renderErrorMessages(errors.fecha_ingreso)}
            <div className='flex'>

              <label htmlFor="date_picker" className={`w-56 flex items-center font-normal text-[#454545] text-sm`} style={{ width: "100px" }}>Fecha Fin.:</label>
              <input
                value={fechaFinal || ""}
                onChange={(e) => { setFechaFinal(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value)) }}

                disabled={tableLoading}

                type="datetime-local"
                min={fechaInicial}
                max={nowLocalString}
                className={`px-2 py-0 bg-[#F9F9F9] border border-[#D9D9D9] text-sm
                rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
                ${tableLoading ? 'opacity-50 cursor-not-allowed': ''}
                ${errors.fecha_ingreso ? 'border-red-500' : 'border-slate-300'}`}
              />

            </div>

            {errors.fecha_ingreso && Array.isArray(errors.fecha_ingreso) && renderErrorMessages(errors.fecha_ingreso)}
          </Box>

          <p variant="subtitle1" className='flex items-center justify-center mb-1 mt-2 underline'>Filtro por Movimiento (Ingresos y/o Salidas): </p>
          <Box sx={{ display: 'flex', gap: '16px', borderTopRightRadius: '8px', borderBottomRightRadius: '8px', marginTop: '8px', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
            <div className="flex items-center">
              <label className={`pe-2 text-sm select-none ${tableLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`} htmlFor='mostrar_ingresos'>Mostrar sólo Ingresos: </label>

              <input type="checkbox" 
              id="mostrar_ingresos"
              className={`${tableLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
              disabled={tableLoading}
              onChange={(e) => {setMostrarIngreso(e.target.checked ? 1 : 0); console.log(mostrarIngreso)}}
              />
            </div>

            <div className="flex items-center">
            <label className={`pe-2 text-sm select-none ${tableLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`} htmlFor='mostrar_salidas'>Mostrar sólo Salidas: </label>

              <input type="checkbox" 
              id="mostrar_salidas"
              className={`${tableLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
              disabled={tableLoading}
              onChange={(e) => {setMostrarSalida(e.target.checked ? 1 : 0); console.log(mostrarSalida)}}
              />
            </div>
          </Box>
        </Box>
      </Box>
    ),

  });

  return (
    <div>

      <div className='mb-20'>

        <MaterialReactTable
          table={table}
        />

      </div>


    </div>
  )
}
