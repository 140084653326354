import React from 'react'

export default function SingleCard(props) {
  return (
    <div className={`bg-white w-80 flex items-center justify-between h-28
                      rounded-sm shadow-lg 
                      px-4 py-6 border-s-4 
                      ${props.color} 
                      mb-4
                      max-[883px]:w-full`}>
        <div>
        <p className={`${props.title_card_style}`}>{props.titulo_tarjeta}</p>
        <p className={`${props.data_card_style}`}>{props.contenido}</p>
        </div>
        <div>
          {props.tarjeta_logo}
        </div>
    </div>
  )
}
