import React, { useEffect } from 'react'
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default function IngresoInsumosSalidasProductos({ id, titulo, texto, labels1, dataIngresosInsumos, labels2, dataGananciaProductos }) {

    useEffect(() => {

        const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        const labelMeses = labels1.map(mes => meses[mes - 1]);
       
        const dataLine = {
            //labels: ["Polo", "Pantalón", "Camisa"],
            labels: labelMeses,
            datasets: [
                {
                    label: "Costo Invertido en Insumos",
                    data: dataIngresosInsumos,
                    fill: false,
                    backgroundColor: 'rgb(102, 61, 0)',
                    borderColor: 'rgb(255, 184, 77)',
                    tension: 0
                },
                {
                    label: "Costo de ganancia en Productos",
                    data: dataGananciaProductos,
                    fill: false,
                    backgroundColor: 'rgb(11, 59, 28)',
                    borderColor: 'rgb(45, 168, 90)',
                    tension: 0.1
                }
            ],
        };

        const configHBar = {
            type: "line",
            data: dataLine,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: texto
                    },
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += `S/.${context.parsed.y.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                                }
                                return label;
                            }
                        }
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: function(value) {
                                return `S/.${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                            }
                        }
                    }
                }
            },
        };

        var chartBar = new Chart(document.getElementById(id), configHBar);

        chartBar.canvas.parentNode.style.height = 'auto';
        chartBar.canvas.parentNode.style.width = '1000px';

        return () => {
            chartBar.destroy()
        }

    }, [id, labels1, dataIngresosInsumos, titulo]);

    return (
        <div className='flex items-center justify-center me-5 mt-5 w-full'>
            <div className="shadow-lg rounded-lg overflow-hidden max-[1319px]:mt-2 max-[1319px]:mb-8 bg-white">
                <div className="py-3 px-5 bg-white text-sm font-semibold border-b-2">{titulo}</div>
                <canvas className="p-1 mx-12" id={id}></canvas>
            </div>
        </div>
    )
}
